<template>
  <a-card size="small" title="Process(es)">
    <div class="process-container">
      <process-step-table
        v-for="(process, processIndex) in taskProcesses"
        :key="processIndex"
        :processIndex="processIndex"
        :process="process.name"
      >
      </process-step-table>
    </div>

    <div class="d-flex pt-2">
      <a-space class="ml-auto">
        <a-button @click="cancelUploadMedia" id="cancel-media-btn">
          Close
        </a-button>
      </a-space>
    </div>
  </a-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ProcessStepTable from './ProcessStepTable.vue';
import types from 'src/store/mutation-types';

export default {
  setup: () => ({
    types
  }),
  components: {
    ProcessStepTable
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['taskProcesses', 'taskName'])
  },
  methods: {
    ...mapActions(['showModal']),
    cancelUploadMedia() {
      this.showModal({
        modalType: types.SET_SHOW_UPLOAD_MEDIA_MODAL,
        value: false
      });
    },
    handleUploadMedia() {}
  }
};
</script>
<style>
.process-container {
  overflow-y: auto;
  height: 50vh;
  padding: 12px;
}
</style>
