import { TYPES } from './shapes';
import { Shape } from './shapes';

export class Text extends Shape {
  type = TYPES.text;
  midx = 0;
  midy = 0;
  fontFamily = 'Calibri';
  text = '';
  fontSize = 48;
  constructor({
    id = -1,
    x = 305,
    y = 215,
    width = 50,
    height = 50,
    displayName = 'New Text',
    color = '#FFFFFF',
    screenWidth = 640,
    screenHeight = 480,
    scaleDirection = null,
    rotation = 0,
    fontSize = 48,
  } = {}) {
    super({ id, x, y, width, height, displayName, color, rotation });
    this.fontSize = fontSize;
    this.calculateMidPoint();
    if (scaleDirection) this.scale(screenWidth, screenHeight, scaleDirection);
    this.text = displayName;
  }

  scaleExt(scaleWidth, scaleHeight) {
    this.x *= scaleWidth;
    this.y *= scaleHeight;

    this.width *= scaleWidth;
    this.height *= scaleHeight;

    this.midx *= scaleWidth;
    this.midy *= scaleHeight;
  }
  calculateMidPoint() {
    this.midx = (this.x + this.x + this.width) / 2;
    this.midy = (this.y + this.y + this.height) / 2;
  }
  deepCopyExt(shape) {
    const { midx, midy, text, fontSize, fontFamily } = shape;
    this.midx = midx;
    this.midy = midy;
    this.fontSize = fontSize;
    this.fontFamily = fontFamily;
  }
  toJSONExt() {
    return {
      midx: this.midx,
      midy: this.midy,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize,
    };
  }
  moveShape(units, direction) {
    if (direction === this.movementDirections.vertical) this.y += units;
    else if (direction === this.movementDirections.horizontal) this.x += units;
  }
  updateScale(value) {
    if (this.fontSize + value < 2) return;
    this.fontSize = this.fontSize + value;
  }
  rotateShape(angle) {
    this.rotation += angle;
  }
}
