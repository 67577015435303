<template>
  <a-row class="w-100 mb-2" :gutter="[24, 8]">
    <a-col span="8">
      <a-typography-text class="label">{{ substep }}</a-typography-text>
    </a-col>

    <a-col :span="isAnnotation ? '8' : '6'">
      <a-select
        v-model:value="stepObjects.static_object"
        style="width: 100%"
        placeholder="Select static region"
        :options="staticObjectOptions"
        class="px-2"
        ref="regionSelect"
        :allowClear="isAnnotation"
        show-search
        :disabled="isStaticDisable || stepNotVerify"
        @change="(value) => changeStaticObject(value, true)"
        :filter-option="undefined"
        id="add-static-obj-select"
      >
      </a-select>
      <p class="warning-msg" v-if="warningMsgs['static']">
        {{ warningMsgs['static'] }}
      </p>
    </a-col>

    <a-col :span="isAnnotation ? '8' : '6'">
      <a-select
        v-if="isAnnotation"
        v-model:value="stepObjects.non_static_object"
        mode="multiple"
        style="width: 100%"
        placeholder="Select objects"
        :options="nonStaticObjectOptions"
        class="px-2"
        ref="multiObjSelect"
        show-search
        @change="(value) => changeStaticObject(value, false)"
        :filter-option="undefined"
        id="annotation-non-static-obj-select"
      ></a-select>
      <p class="warning-msg" v-if="warningMsgs['nonStatic']">
        {{ warningMsgs['nonStatic'] }}
      </p>

      <a-select
        v-if="isVerification"
        v-model:value="stepObjects.non_static_object"
        style="width: 100%"
        :disabled="!stepObjects.verify"
        placeholder="Select objects"
        :options="nonStaticObjectOptions"
        @change="(value) => changeStaticObject(value, false)"
        show-search
        ref="objSelect"
        class="px-2"
        :filter-option="undefined"
        id="verification-non-static-obj-select"
      ></a-select>
    </a-col>

    <a-col span="4" v-if="isVerification">
      <a-checkbox
        v-model:checked="stepObjects.verify"
        @change="handleVerifyStepObject"
        >verify</a-checkbox
      >
    </a-col>
  </a-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { objectAnnotationModes } from '@/config/task-steps-objects.js';
import { CloseCircleOutlined } from '@ant-design/icons-vue';
import { steps as ODsteps } from './config';

export default {
  components: { CloseCircleOutlined },
  props: [
    'processIndex',
    'stepIndex',
    'substep',
    'substepIndex',
    'pipelineStep',
    // 'currentAnnotationObjectMode',
  ],
  setup() {
    return { objectAnnotationModes };
  },
  inject: ['toast'],
  data() {
    return {
      warningMsgs: {
        nonStatic: '',
        static: '',
      },
    };
  },

  computed: {
    ...mapGetters([
      'taskObjects',
      'annotationObjectJson',
      'currentAnnotationObjectMode',
    ]),

    staticObjectOptions() {
      return this.taskObjects
        .filter(({ is_static }) => is_static === true)
        .map(({ id, name }) => ({
          value: name,
          label: name,
          key: id,
        }));
    },

    nonStaticObjectOptions() {
      return this.taskObjects
        .filter(({ is_static }) => is_static !== true)
        .map(({ id, name }) => ({
          value: name,
          label: name,
          key: id,
        }));
    },

    stepObjects() {
      return this.annotationObjectJson[this.currentAnnotationObjectMode][
        this.substepIndex
      ];
    },

    isStaticDisable() {
      const currentmode = this.annotationObjectJson[
        this.currentAnnotationObjectMode
      ];
      const nonStatic = currentmode[this.substepIndex]['non_static_object'];
      if (Array.isArray(nonStatic) && nonStatic.length >= 2) return true;
      else if (Array.isArray(nonStatic) && !nonStatic.length) return false;
      else return false;
    },

    stepNotVerify() {
      if (this.isAnnotation) return false;
      return this.stepObjects['verify'] === false;
    },

    isAnnotation() {
      return (
        this.pipelineStep ===
        ODsteps.findIndex((s) => s.key === 'associate_steps')
      );
    },

    isVerification() {
      return (
        this.pipelineStep ===
        ODsteps.findIndex((s) => s.key === 'step_completion_Check')
      );
    },
  },

  methods: {
    ...mapActions(['changeAnnotationStepsObjects', 'setObjectWarning']),

    handleVerifyStepObject(e) {
      const checked = e.target.checked;
      const obj = {
        stepIndex: Number(this.substepIndex),
        key: 'verify',
        value: checked,
      };
      this.changeAnnotationStepsObjects(obj);
    },

    changeStaticObject(value, is_static) {
      let pass = true;
      const {
        substep,
        annotationObjectJson,
        currentAnnotationObjectMode,
      } = this;

      const stepIndex = this.substepIndex;
      const stepsDict = {
        ...annotationObjectJson[currentAnnotationObjectMode],
      };
      if (currentAnnotationObjectMode === 'annotation') {
        if (!is_static) {
          const nonStatic = stepsDict[stepIndex]['non_static_object'];
          const staticObj = stepsDict[stepIndex]['static_object'];
          if (nonStatic.length > 2) {
            this.warningMsgs['nonStatic'] =
              'objects can not be selected more than two';
            this.$refs.multiObjSelect.blur();
            this.setObjectWarning({ step: stepIndex, err: true });
            pass = false;
            return;
          }

          if (staticObj && nonStatic.length > 1) {
            this.warningMsgs['nonStatic'] =
              'only one non static should be selected if static object is selected';
            this.$refs.multiObjSelect.blur();
            this.setObjectWarning({ step: stepIndex, err: true });
            pass = false;
            return;
          }
        }
      }
      const obj = {
        stepIndex: Number(stepIndex),
        key: is_static === true ? 'static_object' : 'non_static_object',
        value: value,
      };
      if (pass) {
        this.changeAnnotationStepsObjects(obj);
        this.warningMsgs = {
          static: '',
          nonStatic: '',
        };
        currentAnnotationObjectMode === 'annotation' &&
          this.setObjectWarning({ step: stepIndex, err: false });
      }
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.label {
  color: #34495e;
  font-size: 0.875em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.warning-msg {
  color: #dc3545;
  padding: 0 10px;
}
</style>
