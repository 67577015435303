export default {
  data: () => ({
    joystickButtons: [
      {
        dclass: 'top',
        name: 'a-button',
        action: (i) => (i >= 0 ? 'up' : 'down'),
        propsData: {
          class: 'top',
          size: 'small',
          id: 'up',
        },
        icon: 'UpOutlined',
      },
      {
        dclass: 'middle',
        name: 'a-button',
        action: (i) => (i >= 0 ? 'left' : 'right'),
        propsData: {
          class: 'left',
          size: 'small',
          id: 'left',
        },
        icon: 'LeftOutlined',
      },
      {
        dclass: 'middle',
        name: 'a-button',
        action: (i) => (i >= 0 ? 'right' : 'left'),
        propsData: {
          class: 'right',
          size: 'small',
          id: 'right',
        },
        icon: 'RightOutlined',
      },
      {
        dclass: 'bottom',
        name: 'a-button',
        action: (i) => (i >= 0 ? 'down' : 'up'),
        propsData: {
          class: 'bottom',
          size: 'small',
          id: 'down',
        },
        icon: 'DownOutlined',
      },
    ],
    arrowDirections: [
      {
        name: 'a-button',
        direction: true,
        propsData: {
          class: '',
          size: 'small',
          id: 'plus',
        },
        icon: 'PlusOutlined',
      },
      {
        name: 'a-button',
        direction: false,
        propsData: {
          class: '',
          size: 'small',
          id: 'minus',
        },
        icon: 'MinusOutlined',
      },
    ],
    movementButtons: [
      {
        dclass: 'top',
        name: 'a-button',
        direction: -1,
        action: 'vertical',
        propsData: {
          class: '',
          size: 'small',
          id: 'up-m',
        },
        icon: 'ArrowUpOutlined',
        text: 'U',
      },
      {
        dclass: 'middle',
        direction: -1,
        name: 'a-button',
        action: 'horizontal',
        propsData: {
          class: '',
          size: 'small',
          id: 'left-m',
        },
        icon: 'ArrowLeftOutlined',
        text: 'L',
      },
      {
        name: 'a-button',
        direction: 1,
        action: 'rotate',
        propsData: {
          class: '',
          size: 'small',
          id: 'rotate',
        },
        icon: 'ReloadOutlined',
      },
      {
        dclass: 'middle',
        direction: 1,
        name: 'a-button',
        action: 'horizontal',
        propsData: {
          class: '',
          size: 'small',
          id: 'right-m',
        },
        icon: 'ArrowRightOutlined',
        text: 'R',
      },
      {
        dclass: 'bottom',
        direction: 1,
        name: 'a-button',
        action: 'vertical',
        propsData: {
          class: '',
          size: 'small',
          id: 'down-m',
        },
        icon: 'ArrowDownOutlined',
        text: 'D',
      },
    ],
  }),
  methods: {},
};
