import { uuid } from 'vue-uuid';

export const TYPES = {
  rectangle: 'Rectangle',
  arrow: 'Arrow',
  circle: 'Circle',
  image: 'Image',
  text: 'Text',
  gif: 'Gif',
};
export class Rectangle {
  constructor({
    id = -1,
    x = 305,
    y = 215,
    width = 50,
    height = 50,
    displayName = 'New Region',
    screenWidth = 640,
    screenHeight = 480,
    scaleDirection = null,
  } = {}) {
    this.id = id;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.displayName = displayName;
    this.midx = 0;
    this.midy = 0;
    this.type = TYPES.rectangle;

    this.name = uuid.v4();
    this.rotation = 0;
    this.scaleX = 1;
    this.scaleY = 1;
    this.selected = false;

    this.calculateMidPoint();
    if (scaleDirection) this.scale(screenWidth, screenHeight, scaleDirection);
  }

  calculateMidPoint() {
    this.midx = (this.x + this.x + this.width) / 2;
    this.midy = (this.y + this.y + this.height) / 2;
  }
  scale(screenWidth, screenHeight, direction) {
    if (!['up', 'down'].includes(direction.toLowerCase()))
      throw `Scale direction not specified. Direction can be "up" or "down"`;

    if (direction === 'down') {
      screenWidth = 1 / screenWidth;
      screenHeight = 1 / screenHeight;
    }
    this.x *= screenWidth;
    this.y *= screenHeight;
    this.width *= screenWidth;
    this.height *= screenHeight;
  }
  deepCopy(rectangle) {
    const {
      x,
      y,
      width,
      height,
      displayName,
      midx,
      midy,
      type,
      name,
      rotation,
      selected,
    } = rectangle;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.displayName = displayName;
    this.midx = midx;
    this.midy = midy;
    this.type = type;
    this.name = name;
    this.rotation = rotation;
    this.selected = selected;
  }
  toJSON() {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      displayName: this.displayName,
      midx: this.midx,
      midy: this.midy,
      type: this.type,
      name: this.name,
      rotation: this.rotation,
      selected: this.selected,
    };
  }
}

export class Arrow {
  constructor({
    id = -1,
    x = 300,
    y = 200,
    displayName = 'New Arrow',
    screenWidth = 640,
    screenHeight = 480,
    scaleDirection = null,
    points = [],
  } = {}) {
    this.id = id;
    this.x = x;
    this.y = y;
    this.displayName = displayName;
    this.type = TYPES.arrow;
    this.name = uuid.v4();
    this.rotation = 0;
    this.scaleX = 1;
    this.scaleY = 1;
    this.selected = false;
    this.lineLength = 40;
    this.points = points.length ? [...points] : this.linePoints();
    this.offset = {};
    this.calculateOffset();
    this.closed = true;

    if (scaleDirection) this.scale(screenWidth, screenHeight, scaleDirection);
  }

  scale(screenWidth, screenHeight, direction) {
    if (!['up', 'down'].includes(direction.toLowerCase()))
      throw `Scale direction not specified. Direction can be "up" or "down"`;

    if (direction === 'down') {
      screenWidth = 1 / screenWidth;
      screenHeight = 1 / screenHeight;
    }
    this.offset.x *= screenWidth;
    this.offset.y *= screenHeight;

    this.x = this.offset.x;
    this.y = this.offset.y;

    this.points = this.points.map((value, index) =>
      index % 2 === 0 ? value * screenWidth : value * screenHeight
    );
  }

  calculateOffset() {
    const indexJump = 2;
    let lastPoints = this.points.slice(indexJump * 6);
    const [x1, y1] = [
      (lastPoints.at(0) + lastPoints.at(2)) / 2,
      (lastPoints.at(1) + lastPoints.at(3)) / 2,
    ];
    const [x2, y2] = this.points.slice(indexJump * 3, indexJump * 4);

    this.offset = {
      x: (x1 + x2) / 2,
      y: (y1 + y2) / 2,
    };
    this.x = this.offset.x;
    this.y = this.offset.y;
  }

  drawFirstLine() {
    return [this.x, this.y, this.x + this.lineLength, this.y]; //Draws a straight Line
  }

  arrowHead(startX, startY, lengthConstant) {
    const [ax, ay] = [startX, startY - lengthConstant * 0.5];
    const [bx, by] = [ax + lengthConstant, ay + lengthConstant * 0.75];
    const [cx, cy] = [bx - lengthConstant, by + lengthConstant * 0.75];
    const [dx, dy] = [cx, cy - lengthConstant * 0.5];
    return [ax, ay, bx, by, cx, cy, dx, dy];
  }

  linePoints() {
    const firstLine = this.drawFirstLine();
    const firstLineStart = firstLine.slice(0, 2);
    const firstLineEnd = firstLine.slice(2);
    const arrowHead = this.arrowHead(...firstLineEnd, this.lineLength * 0.5);
    const pathBack = [
      firstLineStart.at(0),
      arrowHead.at(-1),
      ...firstLineStart,
    ];

    return firstLine.concat(arrowHead).concat(pathBack);
  }
  toJSON() {
    return {
      x: this.x,
      y: this.y,
      displayName: this.displayName,
      type: this.type,
      name: this.name,
      selected: this.selected,
      points: [...this.points],
      stroke: 'red',
      fill: 'red',
      closed: true,
      offset: { ...this.offset },
    };
  }

  deepCopy(arrow) {
    const { x, y, displayName, type, name, selected, points, offset } = arrow;
    this.x = x;
    this.y = y;
    this.displayName = displayName;
    this.type = type;
    this.name = name;
    this.selected = selected;
    this.points = [...points];
    this.offset = { ...offset };
    this.stroke = 'red';
    this.fill = 'red';
    this.closed = true;
  }
  updatePoints(points) {
    this.points = [...points];
    this.calculateOffset();
  }
}
