<template>
  <div id="model-versions-container">
    <a-row :gutter="[8, 8]" class="w-100">
      <a-col span="24">
        <ModelVersionTable />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import spaceMixin from 'src/mixins/handleSpace';
import { mapGetters, mapActions } from 'vuex';
import dateHelper from '../../../../shared/Helpers/dateHelper';
import types from '@/store/mutation-types.js';
import { userModelVersionModalTable as columns } from 'src/config/model-version-config';
import ModelVersionTable from './ModelVersionTable.vue';

export default {
  components: {
    ModelVersionTable,
  },
  mixins: [spaceMixin],
  setup() {
    return { columns, dateHelper, types };
  },

  data() {
    return {
      newSelectedModel: null,
      modelButtonDict: {},
    };
  },

  computed: {
    ...mapGetters([
      'taskAssociatedModelVersion',
      'isUpdatingAssociateModelVersion',
      'modelVersionsList',
    ]),
  },

  watch: {
    taskAssociatedModelVersion(selectedModel) {
      this.handleChangeModel(selectedModel);
    },
  },

  mounted() {
    if (this.taskAssociatedModelVersion) {
      this.handleChangeModel(this.taskAssociatedModelVersion);
    }
  },

  methods: {
    ...mapActions(['showModal']),

    handleChangeModel(modelId = null) {
      let temp = { ...this.modelButtonDict };
      this.modelVersionsList.forEach(({ id }) => {
        modelId && Number(id) === Number(modelId)
          ? (temp[id] = true)
          : (temp[id] = false);
      });
      this.modelButtonDict = { ...temp };
    },

    closeModal() {
      this.showModal({
        modalType: types.SHOW_MODEL_VERSIONS_MODAL,
        value: false,
      });
    },
  },
};
</script>
<style>
.radioBtn .ant-radio-inner {
  border-color: #555;
}
</style>
