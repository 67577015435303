import S3Service from 'src/services/s3';

export default {
  createAWSFormData(file, data) {
    let awsformData = new FormData();
    Object.keys(data.fields).forEach((key) => {
      awsformData.append(key, data.fields[key]);
    });
    awsformData.append('file', file);
    return awsformData;
  },

  async getProjectorFileUploadURL(s3Key) {
    let payload = {
      bucket: `${localStorage.getItem('organization')}-training`,
      key: s3Key,
    };
    const [error, data] = await S3Service.getPresignedPostURL(payload, false);
    return error ? error : data;
  },

  handleRemoveImage(file, fileList) {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    fileList = newFileList;
    return fileList;
  },
};
