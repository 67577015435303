<template>
  <div id="add-translation-form">
    <a-tag class="h1 mb-1 my-0" color="processing">{{ taskName }}</a-tag>
    <div class="d-flex my-2 align-items-center" :id="taskName + '-steps-time'">
      <a-typography-text class="w-25" :id="taskName + '-text-exp-cycle-time'"
        >Expected Cycle Time:</a-typography-text
      >
      <a-input
        id="expected-cycle-time-input"
        placeholder="Expected Time (s)"
        :value="avgCycleTime"
        @change="handleAvgCycleTimeChange"
      />
    </div>

    <a-card
      size="small"
      title="Process(es)"
      :loading="isUpdatingStepTime"
      :bodyStyle="{ height: `${isUpdatingStepTime ? '44vh' : '100%'}` }"
    >
      <div class="process-container">
        <Process
          v-for="(process, processIndex) in taskProcesses"
          :key="processIndex"
          :processIndex="processIndex"
          :process="process.name"
        />
      </div>
      <div class="d-flex pt-2" id="delete-step-time-popover">
        <a-popconfirm
          title="Are you sure? You want to delete these steps time"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteStepsTime"
          :okButtonProps="{ id: 'step-time-delete-ok-btn' }"
          :cancelButtonProps="{ id: 'step-time-delete-cancel-btn' }"
        >
          <a-button
            danger
            :disabled="isUpdatingStepTime"
            v-if="isStepTimeExist"
            id="delete-step-time-btn"
          >
            Delete Steps Time
          </a-button>
        </a-popconfirm>
        <a-space class="ml-auto">
          <a-button
            @click="
              showModal({
                modalType: types.SET_SHOW_STEP_TIME_MODAL,
                value: false,
              })
            "
            :disabled="isUpdatingStepTime"
            id="cancel-step-time-modal-btn"
          >
            Cancel
          </a-button>
          <a-button
            type="primary"
            @click="addStepTime"
            :disabled="!isStepTimeEdit"
            id="add-step-time-modal-btn"
          >
            {{ `${isStepTimeExist ? 'Update' : 'Add'} Steps Time` }}
          </a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>
<script>
import Process from './Process.vue';
import { mapActions, mapGetters } from 'vuex';
import types from 'src/store/mutation-types';

export default {
  setup: () => ({ types }),

  components: {
    Process,
  },
  computed: {
    ...mapGetters([
      'taskName',
      'stepTime',
      'avgCycleTime',
      'taskProcesses',
      'isStepTimeExist',
      'isUpdatingStepTime',
      'translationLanguage',
      'isDeletingTranslation',
    ]),
    isDisabled() {
      return true;
    },

    isStepTimeEdit() {
      const { avgCycleTime, stepAvgTime } = this.stepTime;
      return Object.keys(stepAvgTime).length > 0 && !!avgCycleTime;
    },
  },

  methods: {
    ...mapActions([
      'addStepTime',
      'changeAvgCycleTime',
      'resetStepTimeState',
      'deleteStepsTime',
      'showModal',
    ]),

    handleAvgCycleTimeChange(e) {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) return;
      this.changeAvgCycleTime(value);
    },
  },
};
</script>
<style scoped>
.h1 {
  font-size: 16px;
}

label {
  width: 35%;
  color: #34495e;
}

.process-container {
  overflow-y: auto;
  height: 44vh;
}
</style>
