export default [
    {
      title: 'Device ID',
      dataIndex: 'deviceId',
      width: '10%',
      align: 'center',
    },
    // {
    //   title: 'Shift',
    //   dataIndex: 'shift',
    //   width: '10%',
    // },
    {
      title: 'Activity',
      dataIndex: 'status',
      width: '30%',
      align: 'center',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '10%',
      align: 'center',
    },
  ];
  