<template>
  <a-card
    title="Sub Step Times"
    size="small"
    hoverable
    :loading="isUpdatingTaskParams"
  >
    <a-row :gutter="[0, 4]">
      <a-card
        v-for="process in processesSubSteps"
        :key="process.name"
        :title="process.name"
        size="small"
        class="w-100"
      >
        <a-col
          v-for="substep in process.sub_steps"
          :key="substep"
          span="24"
          class="form"
        >
          <a-typography-text class="label">
            {{ substep }}
          </a-typography-text>
          <a-input
            :id="substep + '-json-times-input'"
            placeholder="Step time"
            :value="subStepTimesJson[substep]"
            @change="(e) => handleSubStepTimesChange(e, substep)"
          />
        </a-col>
      </a-card>
    </a-row>
    <div class="form">
      <a-typography-text class="label">
        background
      </a-typography-text>
      <a-input
        :id="'bg-json-times-input'"
        placeholder="Background time"
        :value="subStepTimesJson['background']"
        @change="(e) => handleSubStepTimesChange(e, 'background')"
      />
    </div>
  </a-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    processes: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters([
      'processToStepListMap',
      'subStepTimesJson',
      'isUpdatingTaskParams',
    ]),

    processesSubSteps() {
      if (!this.processes) return;
      const processStep = this.processes.reduce((res, el) => {
        let obj = {};
        obj['name'] = el.name;
        obj['sub_steps'] = el.steps.reduce(
          (stepRes, step) => [...stepRes, ...step.substeps],
          []
        );
        return [...res, { ...obj }];
      }, []);
      return processStep;
    },
  },
  methods: {
    ...mapActions(['changeSubStepTimes']),
    handleSubStepTimesChange(e, substep) {
      const { value } = e.target;
      if (!typeof value == 'number' || Number.isNaN(Number(value))) return;
      this.changeSubStepTimes({ step: substep, time: value });
    },
  },
};
</script>
<style scoped>
.form {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.label {
  color: #34495e;
  font-size: 0.875em;
  text-align: end;
  margin-right: 10px;
  width: 80%;
}
</style>
