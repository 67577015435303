export default function(
  taskDetail,
  name = "taskName",
  stepName = "processes",
  setterCallback
) {
  if (!taskDetail) return;

  const task = {
    name: "",
    processes: [],
  };

  const text_lines = taskDetail?.split("\n");
  if (text_lines.length === 0) {
    return;
  }

  task.name = text_lines[0].split("=")[1];
  let substeps = [];
  let steps = [];
  let step = "";
  for (let line of text_lines) {
    line = line.trim();
    if (line.at(-1) === ":" && substeps.length === 0) {
      step = line.slice(0, -1);
    } else if (line.at(-1) === ";") {
      substeps.push(line.slice(0, -1));
    } else if (line.at(-1) === ":" && substeps.length !== 0) {
      steps.push({
        name: step,
        substeps,
      });
      substeps = [];
      step = line.slice(0, -1);
    } else if (line.at(-1) === "~") {
      steps.push({
        name: step,
        substeps,
      });
      task.processes.push({
        name: line.slice(0, -1),
        steps,
      });
      steps = [];
      substeps = [];
      step = "";
    }
  }
  steps.push({
    name: step,
    substeps,
  });
  // debugger;

  if (!task.processes.length)
    task.processes.push({
      name: " Default",
      steps,
    });

  this[name] = task.name;
  if (setterCallback) {
    setterCallback(task.processes);
  } else {
    this[stepName] = task.processes;
  }
}
