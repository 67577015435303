<template>
  <div v-if="showOPCTags">
    <a-modal
      id="opcua-tags-modal"
      :visible="showOPCTags"
      centered
      destroy-on-close
      closable
      :footer="null"
      title="OPC Device Tags"
      width="70vw"
      @cancel="hideOPCDeviceTagComponent"
    >
      <OPCTagsTab :selectedTask="selectedTask" :device="selectedDevice" />
    </a-modal>
  </div>
  <a-table
    v-show="!showOPCTags"
    :columns="columns"
    :data-source="activeDevices"
    :loading="activeDevices.length <= 0"
    :scroll="{ x: true }"
    :pagination="{ position: ['bottomCenter'] }"
    id="opc-device-table"
  >
    <template #emptyText>
      <div
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
        id="opc-no-devices"
      >
        <laptop-outlined style="fontsize: 40px" />
        <span class="mt-2"
          >No Active Device Found. Looking for active device(s).</span
        >
      </div>
    </template>

    <template #headerCell="{ title }" :id="'dep-model' + title + '-hdcell'">
      <div class="text-center">{{ title }}</div>
    </template>

    <template #bodyCell="{ record, column, index }">
      <div
        class="text-center"
        v-if="column.dataIndex === 'deviceId'"
        :id="'dep-model-deviceId-' + index"
      >
        {{ record.display_name || record.Serial_number }}
      </div>

      <div class="text-center" v-if="column.dataIndex === 'action'">
        <a-button
          class="my-1"
          type="primary"
          @click="showOPCDeviceTagComponent(record)"
          :id="'opc-tags-button-' + index"
        >
          Device Tags
        </a-button>
      </div>
    </template>
  </a-table>
</template>

<script>
import OPCTagsTab from './TagList.vue';
import { useActiveDevices } from '../../../RecordData/composables/useActiveDevices';
import { mapGetters } from 'vuex';

export default {
  components: { OPCTagsTab },
  inject: ['toast'],
  props: ['activeDevices'],
  setup() {
    const organization = localStorage.getItem('organization');

    return {
      organization
    };
  },
  data() {
    return {
      showOPCTags: false,
      selectedDeviceSerialNumber: null,
      selectedDevice: null,
      columns: [
        {
          title: 'Device ID',
          dataIndex: 'deviceId',
          width: '10%',
          align: 'center'
        },
        {
          title: 'Action',
          dataIndex: 'action',
          width: '10%',
          align: 'center'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['selectedTask'])
  },
  methods: {
    showOPCDeviceTagComponent(record) {
      this.selectedDevice = record;
      this.selectedDeviceSerialNumber = record.Serial_number;
      this.showOPCTags = true;
    },
    hideOPCDeviceTagComponent() {
      this.showOPCTags = false;
      this.selectedDeviceSerialNumber = null;
      this.selectedDevice = null;
    }
  }
};
</script>

<style></style>
