<template>
  <a-row class="h-100" :gutter="[24, 16]">
    <a-col span="16">
      <a-space>
        <a-input-group class="d-flex" style="width: 25vw !important;">
          <a-input
            v-model:value="staticObjectName"
            placeholder="Enter Object name"
            id="static-object-add-input"
          />
          <a-button
            type="primary"
            id="change-name-btn"
            @click="handleAddRegion"
            :disabled="!staticObjectName"
          >
            {{ isEditName ? 'Update' : 'Assign' }} Name
          </a-button>
        </a-input-group>
        <a-button
          type="primary"
          id="add-new-region-btn"
          class="ml-5"
          :disabled="!image"
          @click="setNewObjectConfigToDraw"
        >
          Add Region
        </a-button>
      </a-space>
    </a-col>
    <a-col span="8">
      <a-typography>Select Label Color:</a-typography>
      <a-select
        :options="[
          { value: 'black', label: 'Black' },
          { value: 'white', label: 'White' },
        ]"
        type="color"
        class="w-50"
        id="change-region-label-color-btn"
        v-model:value="textColor"
      >
      </a-select>
    </a-col>
    <a-col span="16" class="d-flex">
      <a-spin
        :indicator="indicator"
        v-if="imageLoading"
        class="m-auto"
        size="large"
      />

      <a-result
        title="Image Not Found!"
        class="m-auto"
        v-else-if="!imageLoading && !image"
        id="not-found-region-image"
      >
        <template #icon>
          <exclamation-circle-outlined class="text-danger" />
        </template>
      </a-result>

      <v-stage
        v-else
        :key="groups"
        ref="stage"
        :config="stageSize"
        @mousedown="handleStageMouseDown"
        @touchstart="handleStageMouseDown"
        v-contextmenu:contextmenu
        id="region-define-stage"
      >
        <v-layer ref="layer" id="region-define-canvas-layer">
          <v-image
            ref="image"
            :config="{
              image: image,
              name: 'currentImage',
            }"
            id="region-define-canvas-image"
          />
          <v-group
            v-for="(rect, index) in groups.rectangles"
            :key="rect.name + 1"
            :config="{ draggable: true }"
            :id="'region-group' + rect.name + '-' + index"
          >
            <v-rect
              :key="rect.name"
              :config="rect"
              @transformend="handleTransform"
              :id="'region-rect-' + rect.name + '-' + index"
            />
            <!-- <v-circle
              :config="{
                x: rect.midx,
                y: rect.midy,
                radius: 2,
                fill: '#000',
              }"
            /> -->
            <v-text
              :key="index"
              :config="groups.labels[index]"
              :id="'region-label-' + groups.labels[index]?.text"
            />
          </v-group>
          <v-transformer ref="transformer" id="region-define-transformer" />
        </v-layer>
      </v-stage>
      <v-contextmenu ref="contextmenu">
        <v-contextmenu-item
          @click="filterObjectFromList(groups.rectangles[selectedShapeKey])"
          id="region-delete-object-btn"
        >
          Delete
        </v-contextmenu-item>
        <v-contextmenu-item
          @click="selectObjectToEdit(groups.rectangles[selectedShapeKey])"
          id="region-rename-object-btn"
        >
          Rename
        </v-contextmenu-item>
      </v-contextmenu>
    </a-col>
    <a-col span="8" class="d-flex flex-column justify-content-center">
      <a-list
        bordered
        :loading="{ spinning: imageLoading, size: 'large' }"
        item-layout="horizontal"
        :data-source="objectList"
        :style="`height:${stageSize.height}px; overflow-y: auto;`"
        id="marked-region-obj-list"
        size="small"
      >
        <template #header>
          <h6>Defined Regions</h6>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item :id="'region-obj-' + index">
            <template #actions>
              <span
                class="cursor-pointer"
                :id="'region-delete-' + item.actual_name"
                @click="filterObjectFromList(item)"
              >
                <delete-outlined />
              </span>
            </template>
            <a-list-item-meta>
              <template #title>
                <span :id="'region-name-' + item.actual_name">{{
                  item.actual_name
                }}</span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-col>

    <a-col span="24" class="d-flex align-items-end justify-content-end">
      <a-button
        @click="handleCloseRegionDefineModal"
        id="region-modal-close-btn"
        class="mr-2"
      >
        Cancel
      </a-button>

      <a-button
        type="primary"
        id="save-regions-btn"
        @click="saveAnnotation"
        :disabled="isUpdated"
        :loading="annotationSaveLoading"
      >
        Save
      </a-button>
    </a-col>
  </a-row>
</template>
<script>
import { parseStringCoordsToObj } from '../../LabelData/ObjectAnnotation/helpers';
import * as OBJECT from '../../LabelData/ObjectAnnotation/object';
import { mapActions, mapGetters } from 'vuex';
import httpClient from '../../../../../service/httpClient';
import types from '@/store/mutation-types.js';
import objectsMarkingMixin from '@/mixins/objectsMarking';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { uuid } from 'vue-uuid';
import TaskObjectService from '@/services/taskObjectsMarking';

export default {
  setup: () => ({
    OBJECT,
  }),

  inject: ['toast'],

  components: {
    DeleteOutlined,
    ExclamationCircleOutlined,
  },

  mixins: [objectsMarkingMixin],

  beforeMount() {
    this.setImage();
    this.renderExistingStaticObjectList();
  },

  unmounted() {
    this.groups = {
      rectangles: {},
      labels: {},
    };
  },

  data() {
    return {
      staticObjectName: '',
      imageLoading: true,
      image: null,
      objectList: [],
      existedObjectIds: [],
      deleteObjectIds: new Set(),
      updatedStaticObjectIds: new Set(),
      stageSize: {
        width: 640,
        height: 480,
      },
      isMarking: false,
      isEditName: false,
      groups: {
        rectangles: {},
        labels: {},
      },
      selectedShapeName: '',
      selectedShapeKey: '',
      annotationSaveLoading: false,
      textColor: 'white',
    };
  },

  computed: {
    ...mapGetters([
      'selectedTask',
      'organization',
      'taskStaticObjectsList',
      'taskRegionMarkingImgUrl',
    ]),

    isUpdated() {
      const currentRects = Object.values(this.groups.rectangles)
        .map((r) => r.id)
        .sort();
      const oldRects = [...this.existedObjectIds].sort();
      const isEdit = oldRects?.some((id) => {
        return this.updatedStaticObjectIds.has(id);
      });
      if (currentRects.length !== oldRects.length || isEdit) return false;
      for (let i = 0; i < currentRects.length; ++i) {
        if (currentRects[i] !== oldRects[i]) return false;
      }
      return true;
    },
  },

  watch: {
    textColor(newColor) {
      const { labels } = this.groups;
      const newLabels = Object.entries(labels).reduce((res, [key, value]) => {
        res[key] = { ...value, color: newColor, fill: newColor };
        return res;
      }, {});
      this.groups['labels'] = newLabels;
    },
  },

  methods: {
    ...mapActions([
      'showModal',
      'getTaskStaticObjectsList',
      'setTaskImageMarkingUrl',
    ]),

    handleCloseRegionDefineModal() {
      this.showModal({
        modalType: types.SET_SHOW_REGION_DEFINE_MODAL,
        value: false,
      });
    },

    setUpdatedObjectState(obj) {
      if (this.existedObjectIds.includes(obj.id)) {
        this.updatedStaticObjectIds.add(obj.id);
      }
    },
    // rename object
    selectObjectToEdit(obj) {
      this.staticObjectName = obj.actual_name;
      this.isEditName = true;
    },
    // set image
    async setImage() {
      if (this.taskRegionMarkingImgUrl) {
        const img = new window.Image();
        const imgUrl = await this.taskRegionMarkingImgUrl;
        if (!imgUrl) {
          this.imageLoading = false;
          return;
        }
        img.src = imgUrl;
        img.onload = () => {
          this.image = img;
          this.image.width = img.naturalWidth;
          this.image.height = img.naturalHeight;
          this.imageLoading = false;
        };
      } else await this.getImageForObjectsMarking(1);
    },
    // add new object in groups
    setNewObjectConfigToDraw() {
      const object = {
        cordinates_of_static_object: null,
        is_static: true,
        name: 'new_obj',
        id: uuid.v4(),
      };
      let { rectangle, label } = this.createRectLabelGroup(object);
      const newGroups = this.groups;
      newGroups.rectangles[rectangle.name] = { ...rectangle };
      newGroups.labels[rectangle.name] = { ...label };
    },

    // Add / Edit object to list
    handleAddRegion() {
      const { rectangles, labels } = this.groups;
      const isExist = Object.values(labels).some(
        (o) => o.text === this.staticObjectName
      );
      if (isExist) {
        this.toast.error('Object of this name already exists');
        return;
      }
      const currentObj = rectangles[this.selectedShapeKey];
      const currentLabel = labels[this.selectedShapeKey];

      if (!this.isEditName && !this.updatedStaticObjectIds.has(currentObj.id)) {
        currentLabel.text = this.staticObjectName;
        currentObj.actual_name = this.staticObjectName;
        this.objectList.push(currentObj);
      } else if (this.isEditName) {
        this.objectList = this.objectList.map((obj) =>
          obj.id === currentObj.id
            ? { ...obj, actual_name: this.staticObjectName }
            : obj
        );
        currentLabel.text = this.staticObjectName;
        currentObj.actual_name = this.staticObjectName;
        if (this.existedObjectIds.includes(currentObj.id))
          this.updatedStaticObjectIds.add(currentObj.id);
      }
      this.staticObjectName = '';
      this.isEditName = false;
    },

    // set Transformer when click on stage
    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (!e.target?.getStage) return;
      if (e.target === e.target.getStage?.()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }
      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent?.().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }
      // find clicked rect by its name
      const name = e.target.name();
      const rect = this.groups.rectangles[name];
      // if (rect && this.existedObjectIds.includes(rect.id)) {
      //   this.handleResizeTransformer();
      //   return;
      // }
      if (rect) {
        this.setUpdatedObjectState(rect);
        this.selectedShapeName = name;
        this.selectedShapeKey = name;
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },

    handleResizeTransformer() {
      this.$refs.transformer.config = {
        resizeEnabled: false,
        rotateEnabled: false,
      };
    },

    // display existing objects
    renderExistingStaticObjectList() {
      return new Promise(async (resolve, reject) => {
        let objList = this.getObjectListFromState();
        objList.sort((a, b) =>
          a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
        );
        this.objectList = objList.map(({ name, ...res }) => ({
          ...res,
          actual_name: name,
        }));
        const existingStaticObs = objList.filter(OBJECT.hasCoordinates);
        const existGroups = {
          ...this.createRectanglesFromExistingObjects([...existingStaticObs]),
        };
        this.groups = { ...existGroups };
        this.existedObjectIds = existingStaticObs.map(({ id }) => id);
        resolve();
      });
    },

    getObjectListFromState() {
      return this.taskStaticObjectsList
        .filter((t) => t.step_id === null)
        .map((o) => {
          return {
            ...o,
            cordinates_of_static_object: parseStringCoordsToObj(
              o.cordinates_of_static_object
            ),
          };
        });
    },

    // save objects Marking
    async saveAnnotation() {
      // delete existing object if any is removed
      this.annotationSaveLoading = true;
      const deleteIds = this.getDeletedObjects();
      await this.deleteObjects(deleteIds);

      let annotations = this.getAnnotations();
      let staticObjects = annotations.map(OBJECT.nestCoordinates);
      let newAnnotations = this.getNewCreatedObjects(staticObjects, deleteIds);
      let updatedAnnotations = this.getUpdatedObjects(staticObjects, deleteIds);
      newAnnotations.length && (await this.saveNewObjects(newAnnotations));
      updatedAnnotations.length &&
        (await this.updateObjects(updatedAnnotations));
      this.setStateOnSave();
    },

    getNewCreatedObjects(staticObjects, deleteIds) {
      return staticObjects
        .filter(
          (o) =>
            !deleteIds?.includes(o.id) && !this.existedObjectIds.includes(o.id)
        )
        ?.map((obj) => OBJECT.mapStaticObjects(obj, this.selectedTask, true));
    },

    getUpdatedObjects(staticObjects, deleteIds) {
      return staticObjects
        .filter(
          (o) =>
            !deleteIds?.includes(o.id) && this.updatedStaticObjectIds?.has(o.id)
        )
        ?.map((obj) => OBJECT.mapStaticObjects(obj, this.selectedTask, false));
    },

    async saveNewObjects(data) {
      const payload = JSON.stringify(data);
      console.log('create payload ->', payload);
      const [error, _] = await TaskObjectService.saveCreatedRegions(payload);
      if (error) {
        console.log('err ->', error);
        this.toast.error('Error occurred in saving objects');
      }
    },

    async updateObjects(data) {
      const payload = JSON.stringify(data);
      console.log('update payload ->', payload);
      const [error, _] = await TaskObjectService.updateRegions(payload);
      if (error) {
        console.log('update err ->', error);
        this.toast.error('Error occurred in updating objects');
      }
    },

    setStateOnSave() {
      this.annotationSaveLoading = false;
      this.handleCloseRegionDefineModal();
      this.getTaskStaticObjectsList(this.selectedTask);
    },

    // remove Object
    filterObjectFromList(obj) {
      this.deleteObjectIds.add(obj.id);
      const { rectangles, labels } = this.groups;
      const deleteRectKey = Object.entries(rectangles).find(
        ([key, rect]) => rect.id === obj.id
      )[0];
      const deleteLabelKey = Object.entries(labels).find(([key, label]) => {
        const labelId = label.id.split('-text')[0];
        return labelId == obj.id;
      })[0];
      delete this.groups.rectangles[deleteRectKey];
      delete this.groups.labels[deleteLabelKey];
      this.objectList = this.objectList.filter((o) => o.id !== obj.id);
      this.selectedShapeName = '';
      this.selectedShapeKey = '';
      this.updateTransformer();
    },

    getDeletedObjects() {
      const deleteIds = this.existedObjectIds.filter((id) =>
        this.deleteObjectIds.has(id)
      );
      return deleteIds;
    },

    async deleteObjects(ids) {
      if (ids.length == 0) return;
      const params = {
        ids: ids.toString(),
      };
      const [error, _] = await TaskObjectService.deleteRegions(params);
      if (error) {
        console.log('delete error ->', error);
        this.toast.error('Error in deleting objects');
        return;
      }
    },
  },
};
</script>
