<template>
  <a-card :body-style="{ padding: '1em' }" class="my-3">
    <div class="form">
      <a-typography-text
        :id="'text-exp-time-step-' + stepIndex"
        strong
        class="label"
      >
        {{ step }}
      </a-typography-text>
    </div>
    <Substep
      v-for="(substep, substepIndex) in substeps(processIndex, stepIndex)"
      :key="substepIndex"
      :process-index="processIndex"
      :step-index="stepIndex"
      :substep-index="substepIndex"
      :substep="substep"
    />
  </a-card>
</template>
<script>
import { mapGetters } from 'vuex';
import Substep from './Substep.vue';

export default {
  components: {
    Substep,
  },
  props: ['stepIndex', 'step', 'processIndex'],
  computed: {
    ...mapGetters(['substeps']),
  },
};
</script>
<style></style>
