<template>
  <a-list
    bordered
    class="settings-list"
    size="small"
    :data-source="settingsItemList"
    item-layout="horizontal"
  >
    <template #header> Settings </template>
    <template #renderItem="{ item, index }">
      <a-list-item v-if="item === 'labelColor'" class="pl-2 clickable">
        <div>Label Color</div>
        <template #actions>
          <div class="d-flex justify-content-center">
            <span
              class="color-selector"
              :style="`background:${textColor}; width: 30px; height:30px`"
              :disabled="imageLoading"
              :class="{ greyBorder: textColor === 'white' }"
              @click="updateLabelColor(true)"
            />
            <span
              class="color-selector"
              :style="`background:${
                textColor === 'black' ? 'white' : 'black'
              }; width: 30px; height:30px`"
              :disabled="imageLoading"
              :class="{ greyBorder: textColor === 'black' }"
              @click="updateLabelColor(false)"
            />
          </div>
        </template>
      </a-list-item>

      <a-list-item v-if="item === 'labelSize'" class="pl-2 clickable">
        <div>Label Size</div>
        <template #actions>
          <div class="d-flex justify-content-center">
            <a-input-number
              v-model:value="fontSize"
              @change="handleFontSizeChange"
              :min="15"
              :max="30"
              :step="5"
            />
          </div>
        </template>
      </a-list-item>

      <a-list-item v-if="item === 'annotationObject'" class="pl-2">
        <div class="d-flex justify-content-between align-items-center">
          <a-checkbox
            v-model:checked="isChecked"
            :disabled="!annotate"
            @change="handleChange"
            >Auto-Annotate</a-checkbox
          >
        </div>
        <template #actions>
          <a-select
            ref="annotateSelect"
            v-model:value="annotate"
            :size="'small'"
            style="width: 120px"
            :loading="initialLoading"
            :disabled="!annotate || !isChecked"
            :options="annotationObjectList"
          ></a-select>
        </template>
      </a-list-item>

      <a-list-item v-if="item === 'staticFrame'" class="pl-2">
        <div>Static Frame</div>
        <template #actions>
          <div class="d-flex justify-content-center">
            <a-switch
              v-model:checked="staticFrame"
              @change="handleMarkStaticChanged"
            />
          </div>
        </template>
      </a-list-item>
    </template>
  </a-list>
</template>

<script>
import TaskService from 'src/services/tasks';
import { ObjectSettingsConfig } from './config';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['textColor', 'imageLoading', 'objectList', 'modelChoice', 'imageObj'],
  emits: [
    'changeLabelColor',
    'updateCurrentObject',
    'updateChecked',
    'changeLabelSize',
  ],

  data() {
    return {
      settingsItemList: ObjectSettingsConfig,
      annotate: null,
      isChecked: false,
      initialLoading: true,
      fontSize: 15,
      staticFrame: false,
    };
  },

  computed: {
    ...mapGetters(['selectedTask', 'taskStaticAnnotationImage']),

    annotationObjectList() {
      if (this.objectList === null || this.objectList?.length === 0) return [];
      return this.objectList.map((o) => {
        return { value: o.id, label: o.name };
      });
    },
  },

  mounted() {
    this.updateStaticFrame(this.imageObj);
  },

  watch: {
    imageObj(value) {
      this.updateStaticFrame(value);
    },

    annotationObjectList(newList) {
      if (this.initialLoading) {
        this.handleChange(null);
        this.initialLoading = false;
      }
      if (this.annotate) return;
      if (newList.length > 0) {
        this.annotate = newList[0].value;
      }
    },

    annotate(newName) {
      this.$emit('updateCurrentObject', newName);
      this.$refs?.annotateSelect?.blur();
    },
  },

  methods: {
    ...mapActions(['setTaskStaticImage']),

    updateStaticFrame(imageObj) {
      if (this.taskStaticAnnotationImage == imageObj?.id)
        this.staticFrame = true;
      else this.staticFrame = false;
    },

    handleChange(event) {
      this.$emit('updateChecked', this.isChecked);
    },

    updateLabelColor(value) {
      this.$emit('changeLabelColor', value);
    },

    handleFontSizeChange(e) {
      this.$emit('changeLabelSize', this.fontSize);
    },

    async handleMarkStaticChanged(checked, event) {
      const payload = {
        annotation_image_id: checked ? this.imageObj?.id : null,
      };
      let [error, data] = await TaskService.updateTask(
        this.selectedTask,
        payload
      );
      if (error) return;
      this.setTaskStaticImage(payload.annotation_image_id);
    },
  },
};
</script>
<style scoped>
.settings-list {
  display: flex;
  flex-direction: column;
  height: 25% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.settings-list :deep(.ant-list-header) {
  background: lightgray !important;
  font-weight: 600 !important;
}

.settings-list :deep(.ant-spin-nested-loading) {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.color-selector {
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  border: 1.5px solid #d9d9d9;
}

.color-selector:nth-child(2) {
  transform: translate(-10px, 7px);
  z-index: 0;
}
</style>
