export default function validateTagData(tagData) {
  switch (tagData.trigger_on) {
    case 'step completion': {
      if (
        tagData.trigger_params.value === null ||
        tagData.trigger_params.step_idx === null
      ) {
        return false;
      }
      break;
    }

    case 'missed step': {
      if (
        tagData.trigger_params.value === null ||
        tagData.trigger_params.onCompletion === null
      ) {
        return false;
      }

      break;
    }

    case 'step verification': {
      if (
        tagData.trigger_params.comparator === null ||
        tagData.trigger_params.threshold === null ||
        tagData.trigger_params.step_idx === null
      ) {
        return false;
      }

      break;
    }
  }

  return true;
}
