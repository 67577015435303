<template>
  <div class="d-flex align-items-center justify-content-evenly">
    <div class="joystick-controls-wrapper">
      <div class="d-flex">
        <a-row class="joystick-buttons" style="height:100px;width:120px">
          <a-col
            v-for="(button, i) in joystickButtons"
            :key="i"
            :span="i > 0 && i < joystickButtons.length - 1 ? '12' : '24'"
            class="flex-center"
          >
            <component
              :is="button.name"
              v-bind="button.propsData"
              :disabled="
                joystickAndMovementButtonsDisable ||
                  selectedShapeType === 'Arrow' ||
                  selectedShapeType === 'Image' ||
                  selectedShapeType === 'Text' ||
                  selectedShapeType === 'Gif' ||
                  selectedShapeType === 'Circle'
              "
              @click="
                this.$emit(
                  'handleButtonEvents',
                  button.action(this.unit),
                  this.unit
                )
              "
            >
              <component :is="button.icon"></component>
            </component>
          </a-col>
        </a-row>

        <div class="arrows_direction">
          <component
            v-for="(button, i) in arrowDirections"
            :key="i"
            :is="button.name"
            v-bind="button.propsData"
            :disabled="
              joystickAndMovementButtonsDisable ||
                selectedShapeType === 'Arrow' ||
                selectedShapeType === 'Image' ||
                selectedShapeType === 'Text' ||
                selectedShapeType === 'Gif' ||
                selectedShapeType === 'Circle'
            "
            @click="handleButtonDirection(button.direction)"
          >
            <component :is="button.icon"></component>
          </component>
        </div>
      </div>
      <label class="w-100 ml-5 font-weight-bold" style="font-size:14px"
        >Resize Controls</label
      >
    </div>
    <div class="movement-controls-wrapper">
      <a-row class="movement-buttons" style="height:100px;width:120px">
        <a-col
          v-for="(button, i) in movementButtons"
          :key="i"
          :span="i > 0 && i < joystickButtons.length ? '8' : '24'"
          class="flex-center"
        >
          <component
            :is="button.name"
            v-bind="button.propsData"
            :disabled="
              joystickAndMovementButtonsDisable ||
                (button.propsData.id === 'rotate' &&
                  (selectedShapeType === 'Rectangle' ||
                    selectedShapeType === 'Circle'))
            "
            @click="
              this.$emit(
                'handleMoveButtonEvents',
                button.action,
                button.direction * movementUnit
              )
            "
          >
            <component :is="button.icon"></component>
          </component>
        </a-col>
      </a-row>
      <label class="w-100 font-weight-bold" style="font-size:14px"
        >Movement Controls</label
      >
    </div>
  </div>
</template>

<script>
import joystickButtonsMixin from 'src/mixins/joystickControlButtons';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  UpOutlined,
  EditOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  MinusOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  PoweroffOutlined,
  ReloadOutlined,
} from '@ant-design/icons-vue';
export default {
  mixins: [joystickButtonsMixin],
  data: function() {
    return {
      movementUnit: 5,
      buttonOutwards: true,
    };
  },
  mounted() {},
  emits: ['handleButtonEvents', 'handleMoveButtonEvents', 'unitDirection'],
  props: ['joystickAndMovementButtonsDisable', 'unit', 'selectedShapeType'],
  components: {
    DeleteOutlined,
    ExclamationCircleOutlined,
    UpOutlined,
    EditOutlined,
    DownOutlined,
    LeftOutlined,
    RightOutlined,
    PlusOutlined,
    MinusOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    PoweroffOutlined,
    ReloadOutlined,
  },
  methods: {
    handleButtonDirection(direction) {
      if (this.buttonOutwards !== direction) {
        this.buttonOutwards = direction;
        this.joystickButtons.reverse();
        this.$emit('unitDirection',this.buttonOutwards);
      }
    },
  },
};
</script>
<style scoped>
.disabled {
  /* This makes it not clickable */
  pointer-events: none;
  opacity: 0.6; /* This grays it out to look disabled */
}

.cursor-default {
  cursor: default;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrows_direction {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stage-controls-wrapper {
  position: relative;
  width: 100%;
}

.abs-label {
  position: absolute;
  left: 0;
  top: 0;
}

.joystick-controls-wrapper,
.movement-controls-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  padding: 0 8px;
}

.green-region {
  font-weight: bolder;
  background-color: rgb(210, 248, 210);
}
</style>
