<template>
  <div class="main-block">
    <div class="form-group">
      <label>Tower Host:</label>
      <a-input
        style="width: 150px"
        v-model:value="tower_host"
        :disabled="checkBox ? true : false"
        placeholder="192.168.10.1"
      />
      <a-checkbox
        class="ml-2"
        style="width: 150px;"
        v-model:checked="checkBox"
        >Use default IP</a-checkbox
      >
      <a-button
        :disabled="checkBox ? true : false"
        type="primary"
        class="ml-n4"
        @click="updateTowerHost"
        >Update Tower Host</a-button
      >
    </div>
    <div style="justifycontent: space-around">
      <a-row>
        <a-col :span="22">
          <div :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
            <a-select
              class="w-50"
              placeholder="Select Signal"
              :value="selectedSignal"
              ref="signal_input"
              @change="updateSignalTower"
            >
              <a-select-option
                v-for="(item, key) in signal_tower"
                :key="key"
                :value="key"
              >
                {{ labels[key] }}
              </a-select-option>
            </a-select>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-row v-if="signalTower" class="signal-tower-block">
      <a-col class="signal-tower-options-block" :span="12">
        <a-col class="options">
          Emit
          <a-switch v-model:checked="signalTower.enable"></a-switch>
        </a-col>
        <br />
        <a-col
          class="options"
          :class="{
            disabled: !signalTower.enable,
          }"
          >Buzzer<a-switch
            :disabled="!signalTower.enable"
            v-model:checked="signalTower.buzzer"
          ></a-switch
        ></a-col>
        <br />
        <a-col>
          <a-dropdown
            :trigger="['click']"
            class="cursor-pointer"
            :disabled="!signalTower.enable"
            :class="{ disabled: !signalTower.enable }"
          >
            <span class="options"
              >Select Colour:
              <bg-colors-outlined style="fontsize: 20px; marginright: 10px"
            /></span>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  v-for="color in colors"
                  :key="color"
                  @click="assignColorToShape(signalTower, color.hex)"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                  >
                    <span>
                      {{ color.name }}
                    </span>
                    <span class="ml-2">
                      <div
                        :style="{
                          backgroundColor: color.hex,
                          width: '12px',
                          height: '12px',
                        }"
                      ></div>
                    </span>
                  </div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-col>
      </a-col>
      <a-col :span="12">
        <!-- <br /> -->
        <div v-if="signalTower" class="patlite">
          <div
            v-for="n in 5"
            :key="n"
            :style="{ backgroundColor: colors[signalTower?.color]?.hex }"
            class="light"
          />
        </div>
      </a-col>
      <a-button
        type="primary"
        class="mt-3"
        v-if="signalTower"
        @click="saveSignalConfig"
        >Save configuration</a-button
      >
    </a-row>
  </div>
</template>

<script>
import { BgColorsOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue';
import DeviceService from 'src/services/device';
import httpClient from 'src/service/httpClient';

export default {
  props: ['device'],
  inject: ['toast'],
  data() {
    return {
      isSelectedSignal: false,
      colors: {
        11111: { name: 'Red', hex: '#ff0000' },
        22222: { name: 'Amber', hex: '#FFBF00' },
        33333: { name: 'Lemon', hex: '#FAFA33' },
        44444: { name: 'Green', hex: '#98FB98' },
        55555: { name: 'Sky Blue', hex: '#00B5E2' },
        66666: { name: 'Blue', hex: '#0000FF' },
        77777: { name: 'Purple', hex: '#800080' },
        88888: { name: 'Pink', hex: '#F33A6A' },
        99999: { name: 'White', hex: '#FFFFFF' },
      },
      selectedSignal: '',
      signal_tower: {
        start: {
          enable: true,
          color: '44444',
          buzzer: false,
        },
        missing: {
          enable: false,
          color: '11111',
          buzzer: false,
        },
        clear_missing: {
          enable: false,
          color: '44444',
          buzzer: false,
        },
        end: {
          enable: false,
          color: '66666',
          buzzer: false,
        },
        idle: {
          enable: false,
          color: '66666',
          buzzer: false,
        },
        reset: {
          clear: 1,
          enable: 1,
        },
      },
      signalTower: [],
      organization: localStorage.getItem('organization'),
      checkBox: true,
      tower_host: '192.168.10.1',
      labels: {
        start: 'Cycle start',
        missing: 'On step miss',
        clear_missing: 'Miss step completed',
        end: 'Cycle end',
        idle: 'No cycle performed',
      },
      selectedDevice: {},
    };
  },
  watch: {
    signal_tower: function (value) {
      // console.log(value);
    },
    signalTower: function (value) {},
  },
  async created() {
    this.configureSignalTower();
  },
  methods: {
    async configureSignalTower() {
      const order = ['start', 'missing', 'clear_missing', 'end', 'idle'];
      let sortedData = {};
      let deviceSettingsURL = this.getDeviceSettingUrl();
      const list = await httpClient.get(deviceSettingsURL, false);
      const [deviceSetting] = list;
      this.selectedDevice = deviceSetting;
      if (deviceSetting['tower_host']) {
        this.tower_host = deviceSetting['tower_host'];
      }
      if (Object.keys(deviceSetting['tower_settings']).length > 0) {
        this.signal_tower = deviceSetting['tower_settings'];
        order.forEach((key) => {
          if (this.signal_tower.hasOwnProperty(key)) {
            sortedData[key] = this.signal_tower[key];
          }
        });
        this.signal_tower = sortedData;
      }
      delete this.signal_tower['reset'];
      Object.keys(this.signal_tower).map((key) => {
        this.signal_tower[key].enable = Boolean(this.signal_tower[key].enable);
        this.signal_tower[key].buzzer = Boolean(this.signal_tower[key].buzzer);
      });
    },
    getDeviceSettingUrl() {
      return (
        'device/' + this.organization + '/device_settings/' + this.device + '/'
      );
    },
    isValidIPv4(ip) {
      const pattern =
        /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return pattern.test(ip);
    },
    assignColorToShape(signal, color) {
      signal.color = Object.keys(this.colors).find(
        (key) => this.colors[key].hex === color
      );
    },
    toggleEnable(signal) {
      signal.enable = !signal.enable;
    },
    updateSignalTower(signal) {
      this.selectedSignal = signal;
      this.signalTower = this.signal_tower[this.selectedSignal];
      this.isSelectedSignal = true;
      this.$refs.signal_input.blur();
    },
    updateBlock(block) {
      // logic to update block data
    },
    showDevicesPage() {
      this.$emit('hidePateliteTower');
    },
    transformSignalTowerSetting() {
      let transformedSetting = JSON.parse(JSON.stringify(this.signal_tower));
      Object.keys(transformedSetting).map((key) => {
        transformedSetting[key].enable = Number(transformedSetting[key].enable);
        transformedSetting[key].buzzer = Number(transformedSetting[key].buzzer);
      });
      transformedSetting = {
        ...transformedSetting,
        reset: {
          clear: 1,
          enable: 1,
        },
      };
      return transformedSetting;
    },
    async updateTowerHost() {
      if (this.isValidIPv4(this.tower_host)) {
        const payload = {
          tower_host: this.tower_host,
        };
        const [error, data] = await DeviceService.updateDeviceSetting(
          this.selectedDevice?.id,
          this.organization,
          payload
        );
        if (error)
          return this.toast.error('Error in updating tower host', {
            timeout: 3000,
          });
        this.toast.success(
          'Tower host updated. Please restart software to sync tower light with software.',
          {
            timeout: 5000,
          }
        );
        this.checkBox = true;
        return;
      }
      return this.toast.error('Invalid tower host IP', { timeout: 3000 });
    },
    async saveSignalConfig() {
      const payload = {
        tower_settings: this.transformSignalTowerSetting(),
      };
      const [error, data] = await DeviceService.updateDeviceSetting(
        this.selectedDevice?.id,
        this.organization,
        payload
      );
      if (error)
        return this.toast.error('Error in saving patlite configuration', {
          timeout: 3000,
        });
      return this.toast.success('Patlite configuration saved', {
        timeout: 3000,
      });
    },
  },
  mounted() {
    this.updateSignalTower();
  },
  components: {
    BgColorsOutlined,
    ArrowLeftOutlined,
  },
};
</script>

<style>
.main-block {
  background-color: white;
  padding: 20px;
  width: 60%;
  border-radius: 5px;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); */
}
.signal-tower-block {
  justify-content: space-between;
}

.signal-tower-options-block {
  margin-top: 20px;
  text-align: center;
  border: 3px solid white;
  width: 145px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
}
.options {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.patlite {
  width: 150px;
  /* height: 400px; */
  position: relative;
  margin: 20px 20px 5px 100px;
  display: flex;
  flex-direction: column;
  border: 3px solid black;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #333;
  background-color: #f2f2f2;
}

.light {
  width: 142px;
  height: 78px;
  margin: 1px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.disabled {
  /* This makes it not clickable
*/
  pointer-events: none;
  opacity: 0.6; /* This grays it out to look disabled */
}
.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-group label {
  width: 80px;
}
</style>
