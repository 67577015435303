import { uuid } from 'vue-uuid';

export const TYPES = {
  rectangle: 'Rectangle',
  arrow: 'Arrow',
  circle: 'Circle',
  image: 'Image',
  text: 'Text',
  gif: 'Gif',
};

export class ShapeMethodExtensionsInterface {
  toJSONExt() {
    throw 'Must be implemented by child class';
  }
  deepCopyExt(shape) {
    throw 'Must be implemented by child class';
  }
  scaleExt(width, height) {
    throw 'Must be implemented by child class';
  }
  moveShape(units, direction) {
    throw 'Must be implemented by child class';
  }
  resizeShape(shapeClone) {
    throw 'Must be implemented by child class';
  }
  rotateShape(angle) {
    throw 'Must be implemented by child class';
  }
}

export class Shape extends ShapeMethodExtensionsInterface {
  name = uuid.v4();
  scaleX = 1;
  scaleY = 1;
  rotation = 0;
  selected = false;
  scaleDirections = ['up', 'down'];
  movementDirections = {
    vertical: 'vertical',
    horizontal: 'horizontal',
    rotate: 'rotate',
  };
  color = '#FFFFFF';
  outline = false;
  constructor({
    id = -1,
    x = 305,
    y = 215,
    width = 50,
    height = 50,
    displayName = 'New Region',
    color = '#FFFFFF',
    rotation = 0,
    outline = false,
  } = {}) {
    super();
    this.id = id;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.displayName = displayName;
    this.color = color;
    this.rotation = rotation;
    this.outline = outline;
  }
  scale(scaleWidth, scaleHeight, direction) {
    if (!this.scaleDirections.includes(direction.toLowerCase()))
      throw `Error. Scale directions must be either ${this.scaleDirections.join(
        ' or '
      )}`;

    if (direction == 'down') {
      scaleWidth = 1 / scaleWidth;
      scaleHeight = 1 / scaleHeight;
    }
    this.scaleExt(scaleWidth, scaleHeight);
  }

  deepCopy(shape) {
    const {
      x,
      y,
      width,
      height,
      displayName,
      type,
      name,
      selected,
      color,
      rotation,
      outline,
    } = shape;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.displayName = displayName;
    this.type = type;
    this.name = name;
    this.selected = selected;
    this.color = color;
    this.rotation = rotation;
    this.outline = outline;
    this.deepCopyExt(shape);
  }

  toJSON() {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      displayName: this.displayName,
      type: this.type,
      name: this.name,
      rotation: this.rotation,
      selected: this.selected,
      color: this.color,
      outline: this.outline,
      ...this.toJSONExt(),
    };
  }

  addColor(color) {
    this.color = color;
  }
  move(units, direction) {
    const directions = Object.values(this.movementDirections);
    const directionLower = direction.toLowerCase();
    if (!directions.includes(directionLower))
      throw `Shape movement options are ${directions.join(' or ')}`;

    if (direction === this.movementDirections.rotate) this.rotateShape(units);
    else this.moveShape(units, directionLower);
  }
  setOutline(outline) {
    this.outline = outline;
  }
}
