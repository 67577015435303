export function isHandAndFaceObj(objName, modelChoice) {
  if (!objName) return false;
  const name = objName.toLowerCase();
  if (modelChoice === 'Model ADF') {
    return name === 'faces' || name === 'face';
  } else {
    return (
      name === 'hands' || name === 'hand' || name === 'faces' || name === 'face'
    );
  }
}

export const colorsConfig = [
  '#48E5C2', // turquoise,
  '#004e98', //blue
  '#a846a0', //purpureus
  '#cf5c36', //flame
  '#053225', //dark green
  '#3b1f2b', //dark purple
  '#383961', //delft blue
  '#F3FFB6', //mindaro
  '#392f5a', //space cadet
  '#4c8577', //viridian
  '#2C5530', //cal poly green
  '#56351e', // cafenoir
  '#04471C', // pakistan green
  '#a44a3f', //chestnut
  '#5f0f40', //tyrian purple
  '#fcdc4d', //mustard
  '#9a031e', //carmine
  '#4c3b4d', // Eng violet
  '#0081a7', //Cerulean
  '#2c3d55', //purssian blue
  '#183a37', //dark slate gray
  '#FFEBC6', // dutch white
  '#C287E8' //lavendar
];
