<template>
  <a-form :model="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
    <a-card title="Device Parameters" size="small">
      <br />
      <a-form-item label="Device name" :rules="[{ required: true }]">
        <a-select
          v-model:value="selectedTag.device"
          class="w-100"
          show-search
          placeholder="Select Device(s)"
          :options="deviceOptions"
          :loading="isFetchingDevices"
          :filter-option="filterOption"
        />
      </a-form-item>
      <a-form-item label="Task name" :rules="[{ required: true }]">
        <a-select
          v-model:value="selectedTag.task"
          class="w-100"
          show-search
          placeholder="Select Operation"
          :options="taskOptions"
          :filter-option="filterOption"
          :loading="isFetchingTasks"
        />
      </a-form-item>
      <a-form-item
        v-if="
          selectedTag.triggerOn &&
            selectedTag.triggerOn !== 'missed step' &&
            selectedTag.task
        "
        label="Assigned Step"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="selectedTag.triggerParams.step_idx"
          class="w-100"
          show-search
          placeholder="Select Step"
          :options="subStepOptions"
          :filter-option="filterOption"
          :loading="isFetchingTasks"
          :disabled="
            selectedTag.triggerOn == 'missed step' || !selectedTag.triggerOn
              ? true
              : false
          "
        />
      </a-form-item>
    </a-card>
  </a-form>
</template>

<script>
import { getSortedTask } from 'src/utils/task';
import { getSortedDeviceForTag } from 'src/utils/device';
import { mapGetters } from 'vuex';
import spaceMixin from 'src/mixins/handleSpace';

export default {
  props: ['selectedTag', 'subStepOptions'],
  mixins: [spaceMixin],
  emits: ['close'],

  data() {
    return {};
  },

  computed: {
    ...mapGetters(['allTasks', 'devices']),
    taskOptions() {
      return getSortedTask(this.allTasks);
    },
    deviceOptions() {
      return getSortedDeviceForTag(this.devices);
    },
  },
};
</script>

<style>
.process-container {
  height: 50vh;
  overflow-y: auto;
  margin: 0.5em 0;
  padding: 0.5em;
  margin-bottom: 1em;
}

.opc-task-parameters-modal .ant-form-item {
  width: 95%;
}

.opc-task-parameters-modal .ant-card {
  margin-bottom: 1rem;
}

.opc-task-parameters-modal .ant-modal-footer {
  display: block;
}
</style>
