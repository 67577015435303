<template>
  <div class="h-100 d-flex flex-column">
    <a-card
      v-for="(process, processIndex) in taskProcesses"
      :key="process.name"
      hoverable
      :title="process.name"
      :bordered="false"
      class="process-container"
      :body-style="{
        flexGrow: '1',
        height: '1px',
        overflowY: 'auto',
        overflowX: 'hidden',
      }"
    >
      <div v-for="(step, stepIndex) in steps(processIndex)" :key="step.name">
        <a-typography-text
          :id="'annotation-step-' + step.name"
          strong
          class="mb-2 ml-4"
        >
          {{ step.name }}
        </a-typography-text>
        <StepObjects
          v-for="substep in substeps(processIndex, stepIndex)"
          :key="substep"
          :process-index="processIndex"
          :substep="substep"
          :substep-index="stepsToIndexMapping[substep]"
          :step-index="stepIndex"
          :pipeline-step="pipelineStep"
        />
        <a-divider />
      </div>
      <template #actions>
        <a-button
          type="primary"
          class="float-right mr-5"
          @click="handleSaveAnnotationObjects"
        >
          Save
        </a-button>
      </template>
    </a-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import StepObjects from './StepObjects.vue';
import { objectAnnotationModes } from '@/config/task-steps-objects.js';
import TaskService from 'src/services/tasks';

export default {
  components: { StepObjects },
  inject: ['toast'],
  props: ['pipelineStep'],
  emits:['handleDone'],
  setup() {
    return { objectAnnotationModes };
  },

  data() {
    return {
      oldStepObjects: {},
    };
  },

  computed: {
    ...mapGetters([
      'taskProcesses',
      'steps',
      'substeps',
      'stepsToIndexMapping',
      'selectedTask',
      'annotationObjectJson',
      'stepsJsonData',
      'objectToIdMapping',
    ]),
  },

  beforeMount() {
    this.changeAnnotationObjectTab(objectAnnotationModes['verification']);
    this.initExistingObjects();
  },

  methods: {
    ...mapActions([
      'changeAnnotationObjectTab',
      'setStepsJsonData',
      'createStepsJsonData',
    ]),

    initExistingObjects() {
      const json = this.annotationObjectJson['verification'];
      if (!json) return;
      if (!Object.keys(json).length) return;
      const temp = {};
      Object.entries(json).forEach(([stepIdx, object]) => {
        temp[stepIdx] = {
          staticObj: object.static_object,
          nonStaticObj: object.non_static_object,
        };
      });
      this.oldStepObjects = temp;
    },

    async handleSaveAnnotationObjects() {
      await Promise.all([
        this.generateVerificationJson(),
        this.updateObjDetectionRule(),
      ]);
      this.$emit('handleDone');
    },

    getAddObjData(stepIdx, object, is_static) {
      const key = is_static ? 'static_object' : 'non_static_object';
      return {
        step: stepIdx,
        task: this.selectedTask,
        name: object,
        [key]: this.objectToIdMapping[object],
      };
    },

    getRemoveObjData(stepIdx, object) {
      return {
        step: stepIdx,
        task: this.selectedTask,
        obj_id: this.objectToIdMapping[object],
      };
    },

    getObjectDetectinRuleData() {
      const verifyStepData = this.annotationObjectJson['verification'];
      let objToAdd = [];
      let objToRemove = [];

      Object.entries(verifyStepData).forEach(([stepIdx, obj]) => {
        const { static_object, non_static_object, verify } = obj;
        const oldStatic = this.oldStepObjects[stepIdx]['staticObj'];
        const oldNonStatic = this.oldStepObjects[stepIdx]['nonStaticObj'];
        if (static_object && non_static_object && verify === true) {
          // static
          if (oldStatic && static_object !== oldStatic) {
            objToAdd.push(this.getAddObjData(stepIdx, static_object, true));
            objToRemove.push(this.getRemoveObjData(stepIdx, oldStatic));
          } else if (!oldStatic) {
            objToAdd.push(this.getAddObjData(stepIdx, static_object, true));
          }
          // non static
          if (oldNonStatic && non_static_object !== oldNonStatic) {
            objToAdd.push(
              this.getAddObjData(stepIdx, non_static_object, false)
            );
            objToRemove.push(this.getRemoveObjData(stepIdx, oldNonStatic));
          } else if (!oldNonStatic) {
            objToAdd.push(
              this.getAddObjData(stepIdx, non_static_object, false)
            );
          }
        }
        if (static_object && non_static_object && verify === false) {
          objToRemove.push(this.getRemoveObjData(stepIdx, oldStatic));
          objToRemove.push(this.getRemoveObjData(stepIdx, oldNonStatic));
        }
      });
      return { objToAdd: objToAdd, objToRemove: objToRemove };
    },

    async updateObjDetectionRule() {
      const { objToAdd, objToRemove } = this.getObjectDetectinRuleData();
      if (objToAdd.length > 0) {
        const [error, data] = await TaskService.createTaskObjectRules(
          this.selectedTask,
          objToAdd,
          false
        );
        if (error) {
          console.log('error in adding objects');
        }
      }
      if (objToRemove.length > 0) {
        const [err, res] = await TaskService.deleteMultipleObjects(
          objToRemove,
          false
        );
        if (err) {
          console.log('error in removing objects');
        }
      }
    },

    async generateVerificationJson() {
      const steps_data = this.annotationObjectJson['verification'];
      const verifySteps = Object.keys(steps_data)
        .filter((stepIndex) => steps_data[stepIndex]['verify'] === true)
        .map((stIndex) => parseInt(stIndex));
      if (this.validateVerifyObjects(verifySteps)) {
        this.toast.info('Both objects should be selected for step to verify');
        return;
      }

      this.associate_verify_tag(verifySteps);

      const updated_steps_data = this.removeEmptySteps(this.stepsJsonData);
      this.setStepsJsonData(updated_steps_data);
      await this.createStepsJsonData(updated_steps_data);
    },

    validateVerifyObjects(verifySteps) {
      const steps_data = this.annotationObjectJson['verification'];
      return verifySteps.some(
        (el) =>
          (steps_data[el]['static_object'] &&
            steps_data[el]['non_static_object'] == null) ||
          (steps_data[el]['static_object'] == null &&
            steps_data[el]['non_static_object']) ||
          (steps_data[el]['static_object'] == null &&
            steps_data[el]['non_static_object'] == null)
      );
    },

    associate_verify_tag(verifySteps) {
      let temp = Object.keys(this.stepsJsonData).length
        ? JSON.parse(JSON.stringify(this.stepsJsonData))
        : {};
      const steps_data = this.annotationObjectJson['verification'];

      verifySteps.forEach(function(step_idx) {
        const curr_step_data = steps_data[step_idx];
        const static_obj = curr_step_data['static_object'];
        const non_static_obj = curr_step_data['non_static_object'];

        if (static_obj && non_static_obj) {
          if (temp.hasOwnProperty(step_idx.toString())) {
            temp[step_idx.toString()].verify_step = {
              region: static_obj,
              object: non_static_obj,
            };
          } else {
            temp[step_idx.toString()] = {
              verify_step: {
                region: static_obj,
                object: curr_step_data['non_static_object'],
              },
            };
          }
        }
      });
      temp = this.removeUnVerifiedStepObjects(temp);
      this.setStepsJsonData(temp);
    },

    removeUnVerifiedStepObjects(json) {
      const steps_data = this.annotationObjectJson['verification'];
      if (json) {
        Object.keys(json).forEach((sIdx) => {
          if (
            json.hasOwnProperty(sIdx.toString()) &&
            steps_data[sIdx].verify === false
          ) {
            delete json[sIdx]['verify_step'];
          }
        });
      }
      return json;
    },

    removeEmptySteps(json) {
      if (json) {
        Object.keys(json).forEach((sIdx) => {
          if (
            json.hasOwnProperty(sIdx.toString()) &&
            !Object.keys(json[sIdx.toString()]).length
          ) {
            delete json[sIdx];
          }
        });
      }
      return json;
    },
  },
};
</script>

<style scoped>
.process-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
