import { TYPES } from '../components/user-panel/pages/devices/ProjectiorRegion/helper';

export default {
  methods: {
    handleTransform(e) {
      const node = e.target;
      const {
        [TYPES.rectangle]: rectangles,
        [TYPES.arrow]: arrows,
      } = this.groups;
      let objToUpdate = {};
      if (node.attrs.type === TYPES.rectangle) {
        objToUpdate = rectangles[this.selectedShapeName];
        objToUpdate.x = node.x();
        objToUpdate.y = node.y();
        objToUpdate.width = node.width();
        objToUpdate.height = node.height();
        objToUpdate.scaleX = node.scaleX();
        objToUpdate.scaleY = node.scaleY();
        objToUpdate.rotation = node.rotation();
      }
    },

    checkOutofBounds(position, action, node, value) {
      action === 'vertical' || action === 'up' || action === 'down'
        ? (position.y += value)
        : (position.x += value);
      const isOut =
        position.x <= 0 ||
        position.y <= 0 ||
        position.x + node.width() >= 640 * 1.1 ||
        position.y + node.height() >= 480 * 1.1;

      if (isOut) {
        return true;
      }
    },

    findNodeByName(name) {
      const stage = this.$refs.stage.getStage();
      return stage.findOne('.' + name);
    },

    checkOutofBoundsExpansion(position, action, node, value) {
      let isOut = 0;
      if (action === 'right') {
        position.x += value;
        isOut = position.x + node.width() >= 640;
      }
      if (action === 'left') {
        position.x -= value;
        isOut = position.x <= 0;
      }
      if (action === 'down') {
        position.y += value;
        isOut = position.y + node.height() >= 480;
      }
      if (action === 'up') {
        position.y -= value;
        isOut = position.y <= 0;
      }
      if (isOut) {
        return true;
      }
    },
    handleNodeButtonEvents(nodeName, action, value) {
      const node = this.findNodeByName(nodeName);
      const heightCheck = parseInt(node.height() + value);
      const widthCheck = parseInt(node.width() + value);
      if (heightCheck < 0) {
        return false;
      }
      if (widthCheck < 0) {
        return false;
      }
      const position = node.getAbsolutePosition();
      if (
        this.checkOutofBoundsExpansion(position, action, node, value) &&
        value > 0
      ) {
        return false;
      }
      if (action === 'right') {
        node.width(node.width() + value);
      } else if (action === 'down') {
        node.height(node.height() + value);
      } else if (action === 'up') {
        node.y(node.y() - value);
        node.height(node.height() + value);
      } else if (action === 'left') {
        node.x(node.x() - value);
        node.width(node.width() + value);
      }
      this.handleTransform({ target: node });
      return true;
    },
    handleNodeMoveButtonEvents(nodeName, action, value) {
      const { [this.selectedShapeType]: shapeGroup } = this.groups;
      const objToUpdate = shapeGroup[this.selectedShapeName];
      if (
        [TYPES.image, TYPES.gif, TYPES.circle].includes(this.selectedShapeType)
      ) {
        objToUpdate.move(value, action);
        return true;
      } else {
        const node = this.findNodeByName(nodeName);
        const position = node.getAbsolutePosition();
        if (this.checkOutofBounds(position, action, node, value)) return false;
        objToUpdate.move(value, action);
        return true;
      }
    },
  },
};
