import apiHelper from './index';

const updateImageAnnotation =  (payload, id, spinner = true) => {
  return apiHelper.patch(
    `organization/task/annotation_image/${id}/`,
    payload,
    spinner
  );
};

const updateObjectsCount = async (payload, spinner = true) => {
  return await apiHelper.post(
    `organization/task/task_object/update_counts/`,
    payload,
    spinner
  );
};

const updateObjectsCoordinates = async (payload, spinner = true) => {
  return await apiHelper.put(
    `organization/task/task_object/update_delete`,
    payload,
    spinner
  );
};

const uploadObjectFile = (annotation_id, payload, spinner = true) => {
  return apiHelper.post(
    `/organization/task/file_upload/${annotation_id}/`,
    payload,
    spinner
  );
};

const getImageAnnotation = (objectId, spinner = false) => {
  return apiHelper.get(
    `organization/task/annotation_image/${objectId}/`,
    spinner
  );
};

export default {
  updateImageAnnotation,
  updateObjectsCount,
  updateObjectsCoordinates,
  uploadObjectFile,
  getImageAnnotation
};
