<template>
  <a-card :bodyStyle="{ padding: '1em' }">
    <div class="form mb-2" id="process-translation">
      <a-typography-text strong class="label" :id="process + '-name'">{{
        process
      }}</a-typography-text>
      <a-input
        :value="getTranslatedValue"
        :id="process + '-translate-input'"
        @input="handleChangeProcess"
      />
    </div>
    <div class="steps-container">
      <step-translation
        v-for="(step, stepIndex) in steps(processIndex)"
        :key="stepIndex"
        :stepIndex="stepIndex"
        :step="step.name"
        :processIndex="processIndex"
      >
      </step-translation>
    </div>
  </a-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import StepTranslation from './StepTranslation.vue';

export default {
  components: {
    StepTranslation,
  },
  props: ['processIndex', 'process'],
  emits: ['changeStep', 'changeSubstep'],

  computed: {
    ...mapGetters(['steps', 'processesTranslation']),
    getTranslatedValue() {
      return this.processesTranslation[this.processIndex]?.name || '';
    },
  },
  methods: {
    ...mapActions(['updateProcessTranslation']),
    handleChangeProcess(e) {
      const { value: process } = e.target;
      const { processIndex } = this;
      this.updateProcessTranslation({
        processIndex,
        process,
      });
    },
  },
};
</script>
<style scoped>
.form {
  display: flex;
  align-items: center;
}

.label {
  color: #34495e;
  text-align: end;
  margin-right: 10px;
  width: 35%;
}

.form-control {
  height: 30.44px;
  font-size: 0.875em;
  /* width: 65%; */
}

.steps-container {
  /* height: 35vh; */
  height: 34vh;
  overflow-y: scroll;
  padding-right: 1em;
}
</style>
