<template>
  <a-card :bodyStyle="{ padding: '1em' }" class="my-3">
    <div class="form" id="step-translation">
      <a-typography-text strong class="label" :id="step + '-name'">{{
        step
      }}</a-typography-text>
      <a-input
        :value="getTranslatedValue"
        :id="step + '-translate-input'"
        @input="handleStepChange"
      />
    </div>
    <substep-translation
      v-for="(substep, substepIndex) in substeps(processIndex, stepIndex)"
      :key="substepIndex"
      :processIndex="processIndex"
      :stepIndex="stepIndex"
      :substepIndex="substepIndex"
      :substep="substep"
    ></substep-translation>
  </a-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SubstepTranslation from './SubstepTranslation.vue';

export default {
  components: {
    SubstepTranslation,
  },
  props: ['stepIndex', 'step', 'processIndex'],
  emits: ['changeStep', 'changeSubstep'],
  data() {
    return { inputFlag: false };
  },
  computed: {
    ...mapGetters(['substeps', 'stepsTranslation']),
    getTranslatedValue() {
      return (
        this.stepsTranslation(this.processIndex)[this.stepIndex]?.name || ''
      );
    },
  },
  created() {
    console.log('translatedStep', this.translatedStep);
    this.inputFlag = !!localStorage.getItem('inputFlag');
  },
  methods: {
    ...mapActions(['updateStepTranslation']),
    handleStepChange(e) {
      const { value: step } = e.target;
      const { stepIndex, processIndex } = this;
      this.updateStepTranslation({
        step,
        stepIndex,
        processIndex,
      });
    },
    // handleSubstepChange(substepInfo) {
    //   console.log('substep change trnas', substepInfo);
    //   const { substepName, position } = substepInfo;
    //   this.$emit('changeSubstep', {
    //     substepName,
    //     substepPosition: position,
    //     stepPosition: this.index,
    //   });
    // },
    handleSpace(e) {
      if (this.inputFlag) return;
      const el = e.target;
      const position = el.selectionStart + 1;

      const value =
        this.translatedStep.name.slice(0, position - 1) +
        ' ' +
        this.translatedStep.name.slice(
          position - 1,
          this.translatedStep.name.length
        );

      this.$emit('changeStep', { stepName: value, position: this.index });
      this.$nextTick(() => {
        el.setSelectionRange(position, position);
      });
    },
  },
};
</script>
<style scoped>
.form {
  display: flex;
  align-items: center;
}

.label {
  color: #34495e;
  text-align: end;
  margin-right: 10px;
  width: 35%;
}

.form-control {
  height: 30.44px;
  width: 65%;
  font-size: 0.875em;
}
</style>
