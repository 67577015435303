<template>
  <div class="p-1">
    <a-card
      v-for="{ key, title, paramType, type, ...res } in trainingParams"
      :key="key"
      :title="title"
      :loading="isUpdatingTaskParams"
      size="small"
      hoverable
    >
      <a-select
        v-if="type === 'select'"
        :key="options[key]"
        :value="taskParameters[paramType][key] || []"
        class="w-75"
        placeholder="Select"
        :options="options[key]"
        :mode="isAugmentations(key) ? 'multiple' : ''"
        @change="value => changeParamValue(key, value)"
      />
      <a-input
        v-else
        class="w-75"
        :value="taskParameters[paramType][key]"
        :name="key"
        @change="handleNumberChange"
      />
    </a-card>
  </div>
</template>
<script>
import { deepClone } from 'src/utils/task';
import { mapActions, mapGetters } from 'vuex';
import { trainingParams } from './config';
import { models_to_avoid } from 'src/config/models-choice-config';
import augmentationOptions from 'src/config/augmentation-options-config';

export default {
  data() {
    return {
      options: {
        augmentations: augmentationOptions,
        model_choice: []
      },
      trainingParams,
      hands: 'hands',
      faces: 'faces'
    };
  },
  computed: {
    ...mapGetters([
      'taskParameters',
      'isUpdatingTaskParams',
      'modelTypes',
      'selectedTask',
      'taskObjects'
    ])
  },

  watch: {
    modelTypes(val) {
      this.options = {
        ...this.options,
        model_choice: val
          .filter(m => !models_to_avoid.includes(m.name))
          .map(({ id, name }) => ({ label: name, value: name }))
      };
    }
  },

  mounted() {
    this.fetchModelTypes();
  },

  methods: {
    ...mapActions([
      'setTaskParameters',
      'fetchModelTypes',
      'getTaskObjects',
      'addTaskObject'
    ]),
    isAugmentations(key) {
      return key === 'augmentations';
    },
    isModelChoiceBeingUpdatedToAD(key, value) {
      return key === 'model_choice' && value === 'Model AD';
    },
    addDefaultObjectsForModelAD() {
      if (!this.objectExistInTaskObjects(this.hands)) {
        this.addObject(this.hands);
      }

      if (!this.objectExistInTaskObjects(this.faces)) {
        this.addObject(this.faces);
      }
    },
    changeParamValue(paramKey, value) {
      const tempTaskParams = deepClone(this.taskParameters);
      tempTaskParams['trainingParams'][paramKey] = value;

      if (this.isModelChoiceBeingUpdatedToAD(paramKey, value)) {
        this.getTaskObjects(this.selectedTask);
        this.addDefaultObjectsForModelAD();
      }

      this.setTaskParameters(tempTaskParams);
    },

    addObject(object_name) {
      const payload = {
        name: object_name,
        task: this.selectedTask
      };
      this.addTaskObject(payload);
    },

    objectExistInTaskObjects(object_name) {
      let obj_exist = false;

      this.taskObjects.forEach(obj => {
        if (obj.name === object_name) {
          obj_exist = true;
        }
      });
      return obj_exist;
    },

    handleNumberChange(e) {
      const { value, name } = e.target;
      if (Number.isNaN(Number(value))) return;
      this.changeParamValue(name, value);
    }
  }
};
</script>
<style></style>
