import { Shape, TYPES } from './shapes';

export class Circle extends Shape {
  type = TYPES.circle;
  radius = 50;
  constructor({
    id = -1,
    x = 305,
    y = 215,
    width = 50,
    height = 50,
    displayName = 'New Circle',
    color = '#FFFFFF',
    screenWidth = 640,
    screenHeight = 480,
    scaleDirection = null,
    rotation = 0,
    radius = 50,
    outline = false,
  } = {}) {
    super({ id, x, y, width, height, displayName, color, rotation, outline });
    this.calculateMidPoint();
    this.radius = radius;
    if (scaleDirection) this.scale(screenWidth, screenHeight, scaleDirection);
  }

  scaleExt(scaleWidth, scaleHeight) {
    this.x *= scaleWidth;
    this.y *= scaleHeight;

    this.width *= scaleWidth;
    this.height *= scaleHeight;

    this.midx *= scaleWidth;
    this.midy *= scaleHeight;
  }
  calculateMidPoint() {
    this.midx = (this.x + this.x + this.width) / 2;
    this.midy = (this.y + this.y + this.height) / 2;
  }
  deepCopyExt(shape) {
    const { midx, midy, radius } = shape;
    this.midx = midx;
    this.midy = midy;
    this.radius = radius;
  }
  toJSONExt() {
    return {
      midx: this.midx,
      midy: this.midy,
      radius: this.radius,
    };
  }
  moveShape(units, direction) {
    if (direction === this.movementDirections.vertical) this.y += units;
    else if (direction === this.movementDirections.horizontal) this.x += units;
    this.calculateMidPoint();
  }
  rotateShape(angle) {
    this.calculateMidPoint();
    this.rotation += angle;
  }
  updateScale(value) {
    if (this.radius + value < 2) return;
    this.radius = this.radius + value;
  }
}
