import { Shape, TYPES } from './shapes';

export class Arrow extends Shape {
  lineLength = 40;
  offset = {
    x: 0,
    y: 0,
  };
  type = TYPES.arrow;
  scaleShape = 1;
  maxAnimationValue = 75;
  play = true;
  constructor({
    id = -1,
    x = 300,
    y = 200,
    displayName = 'New Arrow',
    color = '#FFFFFF',
    screenWidth = 640,
    screenHeight = 480,
    scaleDirection = null,
    points = [],
    rotation = 0,
    scaleShape = 1,
    maxAnimationValue = 75,
    play = true,
    outline = false,
  } = {}) {
    super({ id, x, y, displayName, color, rotation, outline });
    this.points = points.length ? [...points] : this.linePoints();
    this.scaleShape = scaleShape;
    this.maxAnimationValue = maxAnimationValue;
    this.play = play;
    this.calculateOffset();
    if (scaleDirection) this.scale(screenWidth, screenHeight, scaleDirection);
  }
  drawFirstLine() {
    return [this.x, this.y, this.x + this.lineLength, this.y]; //Draws a straight Line
  }

  arrowHead(startX, startY, lengthConstant) {
    const [ax, ay] = [startX, startY - lengthConstant * 0.5];
    const [bx, by] = [ax + lengthConstant, ay + lengthConstant * 0.75];
    const [cx, cy] = [bx - lengthConstant, by + lengthConstant * 0.75];
    const [dx, dy] = [cx, cy - lengthConstant * 0.5];
    return [ax, ay, bx, by, cx, cy, dx, dy];
  }

  calculateOffset() {
    const indexJump = 2;
    let lastPoints = this.points.slice(indexJump * 6);
    const [x1, y1] = [
      (lastPoints.at(0) + lastPoints.at(2)) / 2,
      (lastPoints.at(1) + lastPoints.at(3)) / 2,
    ];
    const [x2, y2] = this.points.slice(indexJump * 3, indexJump * 4);

    this.offset = {
      x: (x1 + x2) / 2,
      y: (y1 + y2) / 2,
    };
    this.x = this.offset.x;
    this.y = this.offset.y;
  }

  linePoints() {
    const firstLine = this.drawFirstLine();
    const firstLineStart = firstLine.slice(0, 2);
    const firstLineEnd = firstLine.slice(2);
    const arrowHead = this.arrowHead(
      firstLineEnd.at(0),
      firstLineEnd.at(1),
      this.lineLength * 0.5
    );
    const pathBack = [
      firstLineStart.at(0),
      arrowHead.at(-1),
      ...firstLineStart,
    ];

    return firstLine.concat(arrowHead).concat(pathBack);
  }

  updatePoints(points) {
    this.points = [...points];
    this.calculateOffset();
  }

  scaleExt(scaleWidth, scaleHeight) {
    this.offset.x *= scaleWidth;
    this.offset.y *= scaleHeight;
    this.maxAnimationValue *= scaleWidth;
    this.x = this.offset.x;
    this.y = this.offset.y;

    this.points = this.points.map((value, index) =>
      index % 2 === 0 ? value * scaleWidth : value * scaleHeight
    );
  }
  toJSONExt() {
    return {
      offset: { ...this.offset },
      points: [...this.points],
      scaleShape: this.scaleShape,
      maxAnimationValue: this.maxAnimationValue,
      play: this.play,
    };
  }
  deepCopyExt(shape) {
    const { offset, points, scaleShape, maxAnimationValue, play } = shape;
    this.offset = { ...offset };
    this.points = [...points];
    this.scaleShape = scaleShape;
    this.maxAnimationValue = maxAnimationValue;
    this.play = play;
  }

  moveShape(units, direction) {
    let newPoints = [...this.points];
    if (direction === this.movementDirections.vertical)
      newPoints = newPoints.map((point, index) =>
        index % 2 === 1 ? point + units : point
      );
    else if (direction === this.movementDirections.horizontal)
      newPoints = newPoints.map((point, index) =>
        index % 2 === 0 ? point + units : point
      );

    this.updatePoints(newPoints);
  }

  updateScale(value) {
    if (Number((this.scaleShape + value).toFixed(2)) < 0.1) return;
    this.scaleShape = Number((this.scaleShape + value).toFixed(2));
    console.log(this.scaleShape);
  }

  rotateShape(angle) {
    this.rotation += angle;
  }

  togglePlay(play) {
    this.play = play;
  }
}
