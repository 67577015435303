<template>
  <a-card :bodyStyle="{ padding: '1em' }">
    <div class="form mb-2">
      <a-typography-text
        strong
        class="label"
        :id="'step-time-process-' + processIndex"
        >{{ process }}</a-typography-text
      >
    </div>
    <div class="steps-container">
      <Step
        v-for="(step, stepIndex) in steps(processIndex)"
        :key="stepIndex"
        :stepIndex="stepIndex"
        :step="step.name"
        :processIndex="processIndex"
      />
    </div>
  </a-card>
</template>
<script>
import { mapGetters } from 'vuex';
import Step from './Step.vue';

export default {
  props: ['processIndex', 'process'],
  components: {
    Step,
  },
  computed: {
    ...mapGetters(['steps']),
  },
};
</script>
<style></style>
