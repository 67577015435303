<template>
  <div
    id="deleteContextMenu"
    :style="{
      display: deleteShow ? 'initial' : 'none',
    }"
  >
    <button
      id="delete-button"
      @click="filterObjectFromList(groups.rectangles[selectedShapeKey])"
    >
      Delete
    </button>
  </div>

  <a-modal
    :visible="!staticFrameExist"
    title="Image Missing"
    ok-text="Yes"
    cancel-text="No"
    :maskClosable="false"
    :closable="false"
    :centered="true"
    @ok="handleStep(-1)"
    @cancel="handleStep(1)"
  >
    <exclamation-circle-outlined style="color: red" />
    <a-typography-title class="ml-2" :level="5" style="display: inline"
      >No image is available to mark static region. Would you like to proceed to
      the Label Objects step?</a-typography-title
    >
  </a-modal>

  <a-row
    class="h-100 d-flex justify-content-around align-items-center"
    :gutter="[24, 24]"
  >
    <a-col span="14" class="h-100 d-flex flex-column">
      <a-card
        hoverable
        :body-style="{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }"
        size="small"
        class="annotation-image-card d-flex flex-column flex-grow-1"
      >
        <a-spin v-if="imageLoading" class="m-auto" size="large" />

        <a-result
          v-else-if="!imageLoading && !image && !chooseFrame"
          id="not-found-region-image"
          title="Image Not Found!"
          class="m-auto"
        >
          <template #icon>
            <exclamation-circle-outlined class="text-danger" />
          </template>
        </a-result>

        <template #title>
          <div
            class="d-flex align-items-center justify-content-between"
            v-if="!chooseFrame"
          >
            <!-- <a-button
              type="primary"
              @click="chooseFrame = true"
              v-if="!imageLoading && imagesForAnnotation.length"
              >Select Image</a-button
            > -->
            <div class="d-flex mx-2">
              <span
                class="color-selector ml-auto"
                :style="`background:${textColor}`"
                :disabled="imageLoading"
                :class="{ greyBorder: textColor === 'white' }"
                @click="changeLabelColor"
              />
              <span
                class="color-selector"
                :style="`background:${
                  textColor === 'black' ? 'white' : 'black'
                }`"
                :disabled="imageLoading"
                :class="{ greyBorder: textColor === 'black' }"
                @click="changeLabelColor"
              />
            </div>
          </div>

          <a-space v-else class="d-flex justify-content-between">
            <a-button
              class="text-dark"
              type="link"
              @click="chooseFrame = false"
            >
              <template #icon>
                <arrow-left-outlined />
              </template>
            </a-button>

            <a-tooltip
              v-if="sortOrder === 'ascend'"
              title="Click to sort in descending order"
            >
              <a-button
                type="primary"
                shape="circle"
                @click="handleChangeSortOrder('descend')"
              >
                <template #icon>
                  <sort-descending-outlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip
              v-if="sortOrder === 'descend'"
              title="Click to sort in ascending order"
            >
              <a-button
                type="primary"
                shape="circle"
                @click="handleChangeSortOrder('ascend')"
              >
                <template #icon>
                  <sort-ascending-outlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template v-if="!imageLoading && image" #cover>
          <v-stage
            v-if="!chooseFrame"
            id="region-define-stage"
            key="stage"
            ref="stage"
            :config="stageSize"
            @click="handleStageClick"
            @mousedown="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
            @contextmenu="disableWebMenu"
          >
            <v-layer id="region-define-canvas-layer" ref="layer">
              <v-image
                id="region-define-canvas-image"
                ref="image"
                :config="imageConfig"
              />
              <v-group
                v-for="(rect, index) in groups.rectangles"
                :id="'region-group' + rect.name + '-' + index"
                :key="rect.name + 1"
                :config="{ draggable: true }"
                @contextmenu="handleDeleteMenuShow"
              >
                <v-rect
                  :id="'region-rect-' + rect.name + '-' + index"
                  :key="rect.name"
                  :config="rect"
                  @transformend="handleTransform"
                />
                <v-text
                  :id="'region-label-' + groups.labels[index]?.text"
                  :key="index"
                  :config="groups.labels[index]"
                />
              </v-group>
              <v-transformer id="region-define-transformer" ref="transformer" />
            </v-layer>
          </v-stage>

          <div v-else class="d-flex flex-column">
            <a-row
              class="w-100"
              :gutter="[16, 16]"
              :style="{ height: stageSize.height + 'px', 'overflow-y': 'auto' }"
            >
              <a-col span="24" v-if="frameImagesLoading" class="d-flex h-100">
                <a-spin class="m-auto" size="large" />
              </a-col>

              <a-col v-else v-for="image in imagesForAnnotation" span="6">
                <a-badge-ribbon
                  v-if="isStaticAnnotation(image)"
                  color="cyan"
                  text="Static"
                  placement="start"
                >
                  <div
                    @click="handleSelectImage(image)"
                    :class="{
                      'selected-image':
                        selectedImage && selectedImage?.id === image.id,
                    }"
                  >
                    <img class="w-100" :src="image.url" />
                  </div>
                </a-badge-ribbon>

                <div
                  v-else
                  @click="handleSelectImage(image)"
                  :class="{
                    'selected-image':
                      selectedImage && selectedImage?.id === image.id,
                  }"
                >
                  <img class="w-100" :src="image.url" />
                </div>
              </a-col>
            </a-row>
          </div>
        </template>

        <template #actions>
          <a-space
            class="px-3 d-flex align-items-center justify-content-between"
          >
            <a-pagination
              v-if="chooseFrame"
              id="static-object-frames-pagination"
              :total="totalImagesForAnnotation"
              v-model:current="imagePage"
              v-model:pageSize="pageSizeForImages"
              show-size-changer
              :page-size-options="[20, 30, 40, 50]"
              @change="handlePageChange"
            />

            <a-space class="float-right">
              <a-button
                v-if="!chooseFrame"
                id="save-regions-btn"
                type="primary"
                class="float-right"
                :disabled="isUpdated"
                :loading="annotationSaveLoading"
                @click="saveAnnotation"
              >
                Save
              </a-button>
              <a-space v-else class="float-right">
                <a-button
                  type="primary"
                  @click="handleSaveStaticImage"
                  :loading="saveLoadedImage"
                  :disabled="!selectedImage || removeImageFrameLoading"
                >
                  Save Static Frame
                </a-button>
                <a-button
                  type="danger"
                  @click="handleRemoveStaticImage"
                  :loading="removeImageFrameLoading"
                  :disabled="
                    !selectedImage || !isStaticAnnotation(selectedImage)
                  "
                >
                  Remove Static Frame
                </a-button>
              </a-space>
            </a-space>
          </a-space>
        </template>
      </a-card>
    </a-col>

    <a-col span="8" class="h-100 d-flex flex-column">
      <a-list
        id="marked-region-obj-list"
        bordered
        class="static-objects-list"
        :loading="{ spinning: isObjectFetching && image, size: 'large' }"
        item-layout="horizontal"
        :data-source="objectList"
        size="small"
      >
        <template #header>
          <span>Defined Regions</span>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item
            :id="'region-obj-' + index"
            style="cursor: pointer"
            :class="{
              'disable-click': existedObject[item.actual_name] || chooseFrame,
            }"
            @click="setNewObjectConfigToDraw(item)"
          >
            <a-list-item-meta>
              <template #title>
                <span
                  :id="'region-name-' + item.actual_name"
                  :class="{
                    'disable-click':
                      existedObject[item.actual_name] || chooseFrame,
                  }"
                >
                  {{ item.actual_name }}
                </span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-col>
  </a-row>
</template>
<script>
import { parseStringCoordsToObj } from '../../LabelData/ObjectAnnotation/helpers';
import * as OBJECT from '../../LabelData/ObjectAnnotation/object';
import { mapActions, mapGetters } from 'vuex';
import objectsMarkingMixin from '@/mixins/objectsMarking';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  FontSizeOutlined,
  BorderOutlined,
  ArrowLeftOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons-vue';
import TaskObjectService from '@/services/taskObjectsMarking';
import VideoService from 'src/services/videos';
import TaskService from 'src/services/tasks';
import DetectorService from 'src/services/detector';

export default {
  components: {
    DeleteOutlined,
    ExclamationCircleOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
    EditOutlined,
    FontSizeOutlined,
    BorderOutlined,
    ArrowLeftOutlined,
  },

  mixins: [objectsMarkingMixin],

  inject: ['toast'],
  setup: () => ({
    OBJECT,
  }),

  emits: ['updateStep'],

  data() {
    return {
      imageLoading: true,
      image: null,
      objectList: [],
      oldObjectsIds: [],
      deleteObjectame: '',
      selectedShapeKey: '',
      annotationSaveLIds: new Set(),
      updatedStaticObjectIds: new Set(),
      deleteObjectIds: new Set(),
      selectedShapeNoading: false,
      textColor: 'white',
      imageDimensions: { width: 0, height: 0 },
      stageSize: { width: 640, height: 480 },
      isObjectFetching: false,
      annotationSaveLoading: false,
      groups: {
        rectangles: {},
        labels: {},
      },
      existedObject: {},

      totalImagesForAnnotation: 0,
      imagesForAnnotation: [],
      selectedImage: null,
      frameImagesLoading: true,
      imagePage: 1,
      pageSizeForImages: 20,
      sortOrder: 'ascend',
      chooseFrame: false,
      saveLoadedImage: false,
      deleteShow: false,
      staticFrameExist: true,
    };
  },

  computed: {
    ...mapGetters([
      'selectedTask',
      'organization',
      'taskStaticObjectsList',
      'taskRegionMarkingImgUrl',
      'taskObjects',
      'taskStaticAnnotationImage',
    ]),

    isUpdated() {
      const currentRects = Object.values(this.groups.rectangles)
        .map((r) => r.id)
        .sort();
      const oldRects = [...this.oldObjectsIds].sort();
      const isEdit = oldRects?.some((id) => {
        return (
          this.updatedStaticObjectIds?.has(id) || this.deleteObjectIds?.has(id)
        );
      });
      if (currentRects.length !== oldRects.length || isEdit) return false;
      return true;
    },

    imageConfig() {
      return {
        image: this.image,
        name: 'currentImage',
        ...this.stageSize,
      };
    },
  },

  watch: {
    textColor(newColor) {
      const { labels } = this.groups;
      const newLabels = Object.entries(labels).reduce((res, [key, value]) => {
        res[key] = { ...value, color: newColor, fill: newColor };
        return res;
      }, {});
      this.groups['labels'] = newLabels;
    },

    groups() {
      this.updateExistedObjects();
    },
  },

  async beforeMount() {
    this.isObjectFetching = true;
    this.setObjectList();
    const result = await this.setImage();
    if (!this.image || !result) {
      this.imageLoading = false;
      this.staticFrameExist = false;
      this.isObjectFetching = true;
      return;
    }
    await this.updateImageConfig();
    // await this.getAnnotationImages();
    this.renderExistingStaticObjectList();
    this.isObjectFetching = false;
    if (!this.isStaticAnnotation(this.selectedImage))
      await this.setImageStaticFrame();
  },

  unmounted() {
    this.groups = {
      rectangles: {},
      labels: {},
    };
  },

  methods: {
    ...mapActions([
      'showModal',
      'getTaskStaticObjectsList',
      'setTaskImageMarkingUrl',
      'setTaskStaticImage',
      'updateTaskObject',
    ]),

    handleStep(step) {
      this.$emit('updateStep', step);
    },

    async handleRemoveStaticImage() {
      this.removeImageFrameLoading = true;
      const payload = {
        annotation_image_id: null,
      };
      // API CALL
      let [error, data] = await TaskService.updateTask(
        this.selectedTask,
        payload
      );

      this.removeImageFrameLoading = false;
      if (error) {
        return this.toast.error('Failed to remove frame.');
      }
      await this.removeStaticImageCoordinates();
      this.toast.success('Frame removed from static region.');
      this.setTaskStaticImage(null);
      this.resetObjectList();
    },

    resetObjectList() {
      this.objectList = this.getObjectListFromState();
      this.groups = {
        rectangles: {},
        labels: {},
      };
    },

    async removeStaticImageCoordinates() {
      if (!this.objectList?.length) return;
      const promises = this.objectList.map((item) => {
        let payload = {
          objectId: item.id,
          objectData: {
            ...item,
            cordinates_of_static_object: null,
          },
        };
        return this.updateTaskObject(payload);
      });

      await Promise.all(promises);
    },

    isStaticAnnotation(item) {
      if (!item?.id) return false;
      return item.id === this.taskStaticAnnotationImage;
    },

    disableWebMenu(e) {
      e.evt?.preventDefault();
      return false;
    },

    updateExistedObjects() {
      const { labels } = this.groups;
      this.existedObject = Object.values(labels).reduce((res, el) => {
        res[el.text] = true;
        return res;
      }, {});
    },

    updateImageConfig() {
      return new Promise(async (resolve) => {
        const canvas = await this.getCanvasElement();
        const { width, height } = canvas.getBoundingClientRect();
        this.imageDimensions = {
          width,
          height,
        };
        this.stageSize = {
          width,
          height,
        };
        resolve();
      });
    },

    getCanvasElement() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const canvas = document.getElementsByTagName('canvas')[0];
          if (canvas) {
            clearInterval(interval);
            resolve(canvas);
          }
        }, 1000);
      });
    },

    changeLabelColor() {
      if (this.textColor === 'white') this.textColor = 'black';
      else this.textColor = 'white';
    },

    setUpdatedObjectState(obj) {
      this.updatedStaticObjectIds.add(obj.id);
    },

    isObjectExist(name) {
      const { labels } = this.groups;
      const isExist = Object.values(labels).some((o) => o.text === name);
      return isExist;
    },

    // get/set image
    handlePageChange(page) {
      this.getAnnotationImages();
    },

    handleChangeSortOrder(order) {
      this.sortOrder = order;
      this.getAnnotationImages();
    },

    async getAnnotationImages() {
      const sorter = {
        ascend: '',
        descend: '-',
      };
      const params = {
        task: this.selectedTask,
        page: this.imagePage,
        pageSize: this.pageSizeForImages,
        ordering: this.sortOrder ? sorter[this.sortOrder] + 'id' : 'id',
      };
      this.frameImagesLoading = true;
      const [error, data] = await TaskObjectService.getObjectsPaginated(params);
      if (error) {
        this.frameImagesLoading = false;
        this.toast.error('error in getting images');
        return;
      }
      const recentObjects = await this.appendUrlsInObjects(data.results);
      this.frameImagesLoading = false;
      this.imagesForAnnotation = [...recentObjects];
      this.totalImagesForAnnotation = data.count;
    },

    async appendUrlsInObjects(imageObjects) {
      if (!imageObjects.length) return;
      const promises = this.getObjectsUrls(imageObjects);
      return new Promise(async (resolve) => {
        const filesUrls = await Promise.all([...promises]);
        resolve(filesUrls);
      });
    },

    getObjectsUrls(objects) {
      const promises = [];
      objects.forEach((object) => {
        promises.push(this.fetchPresignedUrl(object));
      });
      return promises;
    },

    fetchPresignedUrl(imageObj) {
      if (!imageObj) return;
      const payload = {
        bucket_name: this.organization + '-training',
        object_path: imageObj.path_to_image,
      };
      return new Promise(async (resolve) => {
        const [error, data] = await VideoService.getPresignedUrl(payload);
        if (error) {
          resolve('');
        } else
          resolve({
            ...imageObj,
            url: data.presigned_url,
          });
      });
    },

    setImage() {
      return new Promise(async (resolve) => {
        const objId = this.taskStaticAnnotationImage;
        if (objId) {
          const [error, data] = await DetectorService.getImageAnnotation(objId);
          if (error) resolve(false);
          await this.loadImage(data);
          this.selectedImage = data;
          resolve(true);
        } else {
          this.selectedImage = await this.getDefaultImage();
          if (this.selectedImage === null) resolve(false);
          await this.loadImage(this.selectedImage);
          resolve(true);
        }
      });
    },

    async getDefaultImage() {
      const [error, data] = await TaskObjectService.getObjectsPaginated({
        task: this.selectedTask,
        page: 1,
        pageSize: 1,
        ordering: 'id',
      });
      if (error || data.count === 0) return null;
      return data.results[0];
    },

    // add new object in groups
    setNewObjectConfigToDraw(obj) {
      const objName = obj.actual_name;
      if (this.isObjectExist(objName)) {
        this.toast.info('Object of this name already marked on the image!');
        return;
      }
      const object = {
        cordinates_of_static_object: null,
        is_static: true,
        name: objName,
        id: obj.id,
      };
      this.setUpdatedObjectState(object);
      let { rectangle, label } = this.createRectLabelGroup(object);
      const newGroups = this.groups;
      newGroups.rectangles[rectangle.name] = { ...rectangle };
      newGroups.labels[rectangle.name] = { ...label };
      this.updateExistedObjects();
    },

    handleDeleteMenuShow(e) {
      e.evt?.preventDefault();
      if (!e.target.hasOwnProperty('parent')) {
        return;
      }
      const pos = this.$refs.stage.getStage().getPointerPosition();
      const deleteContextMenu = document.getElementById('deleteContextMenu');
      const name = e.target.name();
      const stage = document.getElementById('region-define-stage');
      if (name && name !== 'currentImage') {
        const { top, left } = stage.getBoundingClientRect();
        const { width } = deleteContextMenu.getBoundingClientRect();
        deleteContextMenu.style.top = top + pos.y + 'px';
        deleteContextMenu.style.left = `${left + pos.x - width}px`;
        this.deleteShow = true;
      }
    },

    handleStageClick(e) {
      e.evt?.preventDefault();
      if (e.evt?.button === 2) return;
      const deleteContextMenu = document.getElementById('deleteContextMenu');
      if (deleteContextMenu.style.display === 'initial') {
        this.selectedShapeName = '';
        this.selectedShapeKey = '';
        this.deleteShow = false;
      }
    },

    // set Transformer when click on stage
    handleStageMouseDown(e) {
      if (!e.target.hasOwnProperty('parent')) return;

      // clicked on stage - clear selection
      if (!e.target?.getStage) return;
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }
      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent?.().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }
      // find clicked shape by its name
      const name = e.target.name();
      const rect = this.groups.rectangles[name];

      if (rect) {
        this.setUpdatedObjectState(rect);
        this.selectedShapeName = name;
        this.selectedShapeKey = name;
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },

    // display existing objects
    setObjectList() {
      let objList = this.getObjectListFromState();
      // objList.sort((a, b) =>
      //   b.name.localeCompare(a.name, 'en', { sensitivity: 'base' })
      // );
      this.objectList = objList;
    },

    renderExistingStaticObjectList() {
      return new Promise(async (resolve, reject) => {
        const existingStaticObs = this.objectList.filter(OBJECT.hasCoordinates);
        const existGroups = {
          ...this.createRectanglesFromExistingObjects([...existingStaticObs]),
        };
        this.groups = { ...existGroups };
        this.oldObjectsIds = existingStaticObs.map(({ id }) => id);
        resolve();
      });
    },

    getNewlyAddedObjs() {
      return this.taskObjects.filter((t) => {
        let obj_exists = false;
        this.taskStaticObjectsList.filter((o) => {
          if (o.name === t.name) {
            obj_exists = true;
          }
        });
        return !obj_exists && t.is_static;
      });
    },

    getObjectListFromState() {
      const new_regions = this.getNewlyAddedObjs().map((o) => {
        return {
          ...o,
          cordinates_of_static_object: parseStringCoordsToObj(
            o.cordinates_of_static_object
          ),
          actual_name: o.name,
        };
      });

      let k = this.taskStaticObjectsList
        .filter((t) => t.step_id === null)
        .map((o) => {
          return {
            ...o,
            cordinates_of_static_object: parseStringCoordsToObj(
              o.cordinates_of_static_object
            ),
            actual_name: o.name,
          };
        });

      k = k.concat(new_regions);
      return k;
    },

    // save objects Marking
    async saveAnnotation() {
      this.annotationSaveLoading = true;
      let annotations = this.getAnnotations();
      let staticObjects = annotations.map(OBJECT.nestCoordinates);
      let updatedAnnotations = this.getUpdatedObjects(staticObjects);
      if (updatedAnnotations.length > 0) {
        await this.updateObjects(updatedAnnotations);
      }

      this.setStateOnSave();
      this.toast.success('Regions Saved Successfully!');

      if (this.isStaticAnnotation(this.selectedImage)) return;
      await this.setImageStaticFrame();
    },

    async setImageStaticFrame() {
      const payload = {
        annotation_image_id: this.selectedImage.id,
      };
      const [error, data] = await TaskService.updateTask(
        this.selectedTask,
        payload
      );
      if (error) {
        return this.toast.error('Failed to set image as static frame.');
      }
      this.setTaskStaticImage(this.selectedImage.id);
    },

    getUpdatedObjects(staticObjects) {
      const updatedObjects = staticObjects
        .filter((o) => this.updatedStaticObjectIds?.has(o.id))
        ?.map((obj) => OBJECT.mapStaticObjects(obj, this.selectedTask, false));

      const updatedObjectIds = new Set(updatedObjects.map((obj) => obj.id));

      const deletedObjects = this.getDeletedObjects()
        ?.filter((obj) => !updatedObjectIds.has(obj.id))
        ?.map((ob) => ({
          ...ob,
          cordinates_of_static_object: null,
        }));
      return [...updatedObjects, ...deletedObjects];
    },

    updateObjects(data) {
      return new Promise(async (resolve) => {
        const payload = JSON.stringify(data);
        const [error] = await TaskObjectService.updateRegions(payload);
        if (error) {
          this.toast.error('Error occurred in updating objects');
        }
        resolve();
      });
    },

    setStateOnSave() {
      this.annotationSaveLoading = false;
      this.getTaskStaticObjectsList(this.selectedTask);
    },

    // remove Object
    filterObjectFromList(obj) {
      this.deleteObjectIds.add(obj.id);
      const { rectangles, labels } = this.groups;
      const deleteRectKey = Object.entries(rectangles).find(
        ([key, rect]) => rect.id === obj.id
      )[0];
      const deleteLabelKey = Object.entries(labels).find(([key, label]) => {
        const labelId = label.id.split('-text')[0];
        return labelId == obj.id;
      })[0];
      delete this.groups.rectangles[deleteRectKey];
      delete this.groups.labels[deleteLabelKey];
      this.selectedShapeName = '';
      this.selectedShapeKey = '';
      this.updateTransformer();
      this.updateExistedObjects();
      this.deleteShow = false;
    },

    getDeletedObjects() {
      const deleteIds = this.objectList.filter(({ id }) =>
        this.deleteObjectIds.has(id)
      );
      return deleteIds || []; // Ensure it always returns an array
    },

    handleSelectImage(imageObj) {
      this.selectedImage = { ...imageObj };
    },
    // get Video
    async handleSaveStaticImage() {
      if (!this.selectedImage) return;
      this.saveLoadedImage = true;
      const payload = {
        annotation_image_id: this.selectedImage.id,
      };
      // API CALL
      const [error, data] = await TaskService.updateTask(
        this.selectedTask,
        payload
      );
      if (error) {
        this.saveLoadedImage = false;
        return;
      }
      this.setTaskStaticImage(this.selectedImage.id);
      this.chooseFrame = false;
      this.loadSelectedImage(this.selectedImage);
      this.saveLoadedImage = false;
    },

    loadSelectedImage(imageObj) {
      this.imageLoading = true;
      return new Promise(async (resolve, reject) => {
        const img = new window.Image();
        const imgUrl = await imageObj.url;
        if (!imgUrl) {
          this.imageLoading = false;
          return;
        }
        img.src = imgUrl;
        img.onload = () => {
          this.image = img;
          this.image.width = img.naturalWidth;
          this.image.height = img.naturalHeight;
          this.imageLoading = false;
          resolve();
        };
      });
    },
  },
};
</script>
<style>
.float-right {
  float: right !important;
}

.annotation-image-card > .ant-card-cover {
  padding: 1em !important;
  flex-grow: 1;
  display: flex !important;
}

.annotation-image-card > .ant-card-cover > div {
  display: flex !important;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content {
  width: unset !important;
  height: unset !important;
  flex-grow: 1 !important;
  display: flex;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content > canvas {
  border: 1.5px solid #d9d9d9 !important;
  border-radius: 5px;
  flex-grow: 1;
  width: 100% !important;
  height: 100% !important;
}

.static-objects-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.static-objects-list > .ant-list-header {
  background: lightgray !important;
  font-weight: 600 !important;
}

.static-objects-list > .ant-spin-nested-loading {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.disable-click {
  pointer-events: none;
  color: lightgray;
}

.selected-image {
  border: 4px solid #0090c1;
  border-radius: 6px;
  padding: 2px;
}

#deleteContextMenu {
  display: none;
  position: absolute;
  width: 70px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 6px;
  z-index: 99999;
}

#deleteContextMenu button {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 8px 12px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
}

#static-object-frames-pagination
  > li:last-child
  .ant-pagination-options-size-changer.ant-select {
  width: 90px !important;
}
.color-selector {
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  border: 1.5px solid #d9d9d9;
}

.color-selector:nth-child(2) {
  transform: translate(-10px, 7px);
  z-index: 0;
}
</style>
