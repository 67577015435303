<template>
  <a-modal
    v-model:visible="showMediaFile"
    centered
    destroy-on-close
    :footer="null"
    @cancel="showMediaFile = false"
  >
    <template #title>
      <a-typography-text>
        {{ previewStepObj.name }}
      </a-typography-text>
    </template>
    <PreviewStepMedia
      :record="previewStepObj"
      :step-index="previewStepObj.step_index"
    />
  </a-modal>

  <a-table
    class="ant-table-striped rounded mb-4"
    bordered
    :columns="columns"
    :pagination="false"
    :dataSource="allStepsData"
    :row-class-name="
      (_record, index) => (!_record.is_substep ? 'table-striped' : null)
    "
    :scroll="{ y: '40vh' }"
  >
    <template #title>
      <a-typography-text strong class="process-label" :id="process + '-name'">{{
        process
      }}</a-typography-text>
    </template>

    <template #bodyCell="{record,column}">
      <span v-if="column.dataIndex === 'name' && !record.is_substep">
        <a-typography-text class="step_name">
          {{ record.name }}
        </a-typography-text>
      </span>

      <span v-if="column.dataIndex === 'name' && record.is_substep">
        <a-typography-text style="font-weight: 500; margin-left: 20px">
          {{ record.name }}
        </a-typography-text>
      </span>

      <span v-if="column.dataIndex === 'status' && record.is_substep">
        <a-progress
          v-if="record.uploadProgress"
          type="circle"
          :percent="record.uploadProgress"
          :width="30"
        />
        <span v-else>
          {{
            record.is_uploading
              ? 'Uploading...'
              : taskStepsMediaObject?.[record.step_index]
              ? 'Uploaded'
              : 'Not Uploaded'
          }}</span
        >
      </span>

      <span v-if="column.dataIndex === 'action'">
        <a-space class="upload-media-action">
          <a-upload
            :file-list="fileList"
            :multiple="false"
            :beforeUpload="beforeUpload"
            :show-upload-list="false"
            @change="file => handleChange(file, record.name)"
          >
            <a-button
              shape="circle"
              type="primary"
              :loading="record.uploadProgress > 0 || record.is_uploading"
              :disabled="record.uploadProgress > 0 ? true : false"
              size="small"
            >
              <template #icon>
                <EditOutlined
                  v-if="taskStepsMediaObject?.[record.step_index]"
                />
                <PlusOutlined v-else />
              </template>
            </a-button>
          </a-upload>

          <a-button
            shape="circle"
            type="primary"
            size="small"
            :disabled="!taskStepsMediaObject?.[record.step_index]"
            @click="handlePreviewFile(record)"
          >
            <template #icon>
              <EyeOutlined />
            </template>
          </a-button>

          <a-popconfirm
            title="Are you sure? You want to delete media for this step."
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteMediaFileFromS3(record.step_index)"
          >
            <a-button
              shape="circle"
              danger
              size="small"
              :disabled="!taskStepsMediaObject?.[record.step_index]"
              @click=""
            >
              <template #icon>
                <DeleteOutlined />
              </template>
            </a-button>
          </a-popconfirm>
        </a-space>
      </span>
    </template>
  </a-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons-vue';
import { Upload } from 'ant-design-vue';
import S3Service from 'src/services/s3';
import axios from 'axios';
import uploadFile from 'src/components/shared/Helpers/uploadFile';
import PreviewStepMedia from './PreviewStepMedia.vue';

export default {
  props: ['processIndex', 'process'],
  inject: ['toast'],
  setup: () => ({
    columns: [
      {
        title: 'Step name',
        dataIndex: 'name',
        key: 'name',
        width: '60%',
        customCell: (record, index) => {
          return {
            colSpan: record.is_substep ? 1 : 3
          };
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: '20%',
        customCell: (record, index) => {
          return {
            colSpan: record.is_substep ? 1 : 0
          };
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '20%',
        customCell: (record, index) => {
          return {
            colSpan: record.is_substep ? 1 : 0
          };
        }
      }
    ],
    allowedImages: ['png', 'jpg', 'jpeg'],
    allowedVideos: ['mp4']
  }),
  components: {
    DeleteOutlined,
    PlusOutlined,
    EditOutlined,
    EyeOutlined,
    PreviewStepMedia
  },
  data() {
    return {
      fileList: [],
      allStepsData: [],
      selectedStepName: null,
      showMediaFile: false,
      previewStepObj: {}
    };
  },
  mounted() {
    this.getAllStepsData();
  },
  computed: {
    ...mapGetters([
      'organization',
      'selectedTask',
      'steps',
      'taskName',
      'stepsToIndexMapping',
      'taskStepsMediaObject',
      'indexToStepsMapping'
    ])
  },
  watch: {
    // taskStepsMediaObject(dict) {
    //   if (!dict || !Object.keys(dict)?.length) return;
    //   // this.getAllStepsData();
    // }
  },
  methods: {
    ...mapActions(['getTaskStepsMediaObj', 'patchTaskLastUpdate']),

    getAllStepsData() {
      const processSteps = this.steps(this.processIndex);
      if (!processSteps.length) return;
      let data = [];
      processSteps.forEach(step => {
        data.push({ name: step.name, is_substep: false });
        step.substeps.forEach(ss => {
          const ss_index = this.stepsToIndexMapping[ss];
          data.push({
            name: ss,
            is_substep: true,
            uploadProgress: 0,
            is_uploading: false,
            step_index: this.stepsToIndexMapping[ss]
          });
        });
      });
      console.log('all steps:', data);
      this.allStepsData = data;
    },

    handleChange({ file }, stepName) {
      this.fileList = [file];
      this.handleUploadMediaFile(file, stepName);
    },

    beforeUpload(file) {
      const isVideo = file.type.includes('video');
      const isImage = file.type.includes('image');
      const isLt2M = file.size / 1024 / 1024 < 300;

      // if ((isVideo && isLt2M) || (isImage && isLt2M)) return false;

      let file_type = '';
      if (isImage) file_type = file?.type.split('image/')[1];
      else if (isVideo) file_type = file?.type.split('video/')[1];
      console.log('file before type '.file_type, file);
      let msg = '';

      if (!isVideo && !isImage)
        msg = `Only Video or Image file can be uploaded!`;
      else if (!isLt2M) msg = `Media file must be smaller than 300MB!`;
      else if (isImage && !this.allowedImages.includes(file_type))
        msg = 'Only png and jpeg image file can be uploaded!';
      else if (isVideo && !this.allowedVideos.includes(file_type))
        msg = 'Only mp4 video file can be uploaded';

      if (msg) {
        this.toast.error(msg);
        return Upload.LIST_IGNORE;
      } else return false;
    },

    async handleUploadMediaFile(fileObj, stepName) {
      const step_index = this.stepsToIndexMapping[stepName];
      const isVideo = fileObj.type.includes('video');
      const isImage = fileObj.type.includes('image');

      const oldObj = this.taskStepsMediaObject?.[step_index];
      if (oldObj) await this.deleteMediaFileFromS3(step_index);

      if (isImage) {
        this.saveMediaViaPresignedURL(fileObj, stepName, 'image');
      } else if (isVideo) {
        this.saveMediaViaPresignedURL(fileObj, stepName, 'video');
      }
    },

    async saveMediaViaPresignedURL(file, stepName, fileType) {
      const step_index = this.stepsToIndexMapping[stepName];

      let file_type = '';
      if (fileType === 'image') file_type = file?.type.split('image/')[1];
      else if (fileType === 'video') file_type = file?.type.split('video/')[1];

      let s3Key = `${this.taskName}/TaskMedia/${step_index}.${file_type}`;
      const data = await uploadFile.getProjectorFileUploadURL(s3Key);
      if (!data) {
        this.toast.error('Could not access storage');
        return;
      }
      let awsformData = uploadFile.createAWSFormData(file, data);
      await this.uploadFileToS3(data.url, awsformData, stepName);
    },

    async uploadFileToS3(url, data, stepName) {
      // const fileName = data.get('file')?.name;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*'
      };

      await axios.post(url, data, {
        headers,
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          this.updateItem(stepName, { uploadProgress: percent });
        }
      });
      this.updateItem(stepName, {
        uploadProgress: 0,
        is_uploading: true
      });
      await this.patchTaskLastUpdate();
      await this.getTaskStepsMediaObj(this.selectedTask);
      this.selectedStepName = '';
      this.updateItem(stepName, {
        is_uploading: false
      });
    },

    updateItem(stepName, obj) {
      let index = this.allStepsData.findIndex(item => item.name === stepName);
      const temp = [...this.allStepsData];
      if (!temp[index]) return;
      temp[index] = {
        ...temp[index],
        ...obj
      };
      this.allStepsData = [...temp];
    },

    async deleteMediaFileFromS3(step_index) {
      const payload = {
        organization: this.organization,
        bucket: `${this.organization}-training`,
        file_path: this.taskStepsMediaObject[step_index]?.path
      };
      const [error, _] = await S3Service.deleteFile(payload, false);
      if (error) {
        this.toast.error('Error occurred in deleting file!');
      }
      this.getTaskStepsMediaObj(this.selectedTask);
    },

    handlePreviewFile(record) {
      this.previewStepObj = record;
      this.showMediaFile = true;
    }
  }
};
</script>
<style>
.process-label {
  color: #34495e;
  text-align: end;
  margin-right: 10px;
  width: 35%;
}

.ant-table-striped .table-striped td {
  background-color: rgb(241, 241, 241) !important;
}
</style>
